module.exports = {
      'ContactBarInfo1': 'Masz pytania? Zadzwoń do nas:',
      'ContactBarInfo2': '(pon-pt. 8-16) lub napisz na:',
      'ContactBarInfo3': 'Szybki kontakt:',
      'ContactBarInfo4': '(pon-pt. 8-16). E-mail:',

      'Dodaj do koszyka': 'Dodaj do koszyka',
      'Koszyk': 'Koszyk',
      'Twój koszyk jest pusty.': 'Twój koszyk jest pusty.',
      'Wartość koszyka': 'Wartość koszyka',
      'Przejdź do kasy': 'Przejdź do kasy',
      'Znaleziono': 'Znaleziono',
      'Szukaj produktów': 'Szukaj produktów',
      'Ceny: od': 'Ceny: od',
      'od ': 'od ',
      'najwyższej': 'najwyższej',
      'najniższej': 'najniższej',
      'loading': 'ładuję',

      'Strona Główna': 'Strona Główna',
      'Produkty': 'Produkty',
      'Seria F': 'Seria F',
      'Seria G': 'Seria G',
      'Seria L': 'Seria L',
      'Seria Loft': 'Seria Loft',
      'Seria S': 'Seria S',
      'Seria W': 'Seria W',

      'Zawartość zestawu': 'Zawartość zestawu',
      'Główne cechy': 'Główne cechy',
      'Gwarancja, zwroty, dostawa': 'Gwarancja, zwroty, dostawa',
      'Przydatne informacje': 'Przydatne informacje',
      'Rysunki techniczne': 'Rysunki techniczne',
      'Do pobrania': 'Do pobrania',
      'Może Ci się spodobać': 'Może Ci się spodobać',

      'warranty_info': 'Udzielamy 5 letniej gwarancji na wszystkie nasze produkty.',
      'return_info': 'Masz 30 dni żeby zmienić zdanie i zwrócić towar.',
      'shipping_info_0': 'Dostawa ',
      'shipping_info_1': 'Koszt dostawy: 13.90 zł dla przesyłek o dł. do 250 cm i 25.90 zł dla pozostałych przesyłek',
      'shipping_info_1B': 'Koszt dostawy: 25.90 zł',
      'shipping_info_2': 'Wysyłka 2-3 dni robocze',
      'shipping_info_3': 'Kurier potrzebuje 1-2 dni robocze na dostawę.',
      'shipping_info_4': 'Wysyłka 5-9 dni roboczych',

      //Menu
      'Systemy do drzwi przejściowych': 'Systemy do drzwi',
      'Systemy do drzwi w szafach': 'Systemy do szaf',
      'Seria S – przesuwne': 'Seria S – przesuwne',
      'Seria G – przesuwne do szkła': 'Seria G – przesuwne do szkła',
      'Seria Loft – przesuwne': 'Seria Loft – przesuwne',
      'Seria F – składane': 'Seria F – składane',
      'Seria W – przesuwne': 'Seria W – przesuwne',


      'Akcesoria': "Akcesoria",
      'Akcesoria G100': "Akcesoria G100",
      'Akcesoria Loft': "Akcesoria Loft",
      'Systemy Loft': "Systemy Loft",

      // Konfigurator 
      'Wariant': 'Wariant',
      'Produkt': 'Produkt',
      'Prowadnica': 'Prowadnica',
      'Długość': 'Długość',
      'Mocowanie': 'Mocowanie',
      'Do stropu': 'Do stropu',
      'Do ściany': 'Do ściany',
      'Grubość drzwi': 'Grubość drzwi',
      'Maskownica aluminiowa': 'Maskownica aluminiowa',
      'Uchwyt maskownicy drewnianej': 'Uchwyt maskownicy drewnianej',
      'Uchwyt maskow. drewn.': 'Uchwyt maskow. drewn.',
      'Tak': 'Tak',
      'Nie': 'Nie',
      'Miękkie domykanie': 'Miękkie domykanie',
      '1 strona': '1 strona',
      '2 strony': '2 strony',
      "Otwieranie synchroniczne": 'Otwieranie synchroniczne',

      // Zawartosc zestawu 
      'Wózek z materiału o wysokiej wytrzymałości — poliacetalu. Osie kół ze stali łożyskowej w łożyskach kulkowych': 'Wózek z materiału o wysokiej wytrzymałości — poliacetalu. Osie kół ze stali łożyskowej w łożyskach kulkowych',
      'Wózek z materiału o wysokiej wytrzymałości — poliacetalu. Osie kół ze stali łożyskowej w łożyskach igiełkowych': 'Wózek z materiału o wysokiej wytrzymałości — poliacetalu. Osie kół ze stali łożyskowej w łożyskach igiełkowych',
      'Prowadnica aluminiowa z otworami pod wkręty Ø 4 mm': 'Prowadnica aluminiowa z otworami pod wkręty Ø 4 mm',
      'Stoper blokujący drzwi w krańcowych położeniach': 'Stoper blokujący drzwi w krańcowych położeniach',
      'Stoper odbijający': 'Stoper odbijający',
      'Mocowanie szkła': 'Mocowanie szkła',
      'Wkładki elastomerowe do zacisków dla różnej grubość szkła': 'Wkładki elastomerowe do zacisków dla różnej grubość szkła',
      'Samoprzylepne uchwyty do drzwi z bezbarwnego tworzywa': 'Samoprzylepne uchwyty do drzwi z bezbarwnego tworzywa',
      'Prowadnik dolny wykonany z bezbarwnego tworzywa': 'Prowadnik dolny wykonany z bezbarwnego tworzywa',
      'Klucz imbusowy': 'Klucz imbusowy',
      'Komplet kluczy imbusowych': 'Komplet kluczy imbusowych',
      'Obsada czopu górnego': 'Obsada czopu górnego',
      'Czop górny': 'Czop górny',
      'Czop dolny': 'Czop dolny',
      'Obsada czopu dolnego': 'Obsada czopu dolnego',
      'Zawias': 'Zawias',
      'Wkręty montażowe': 'Wkręty montażowe',
      'Wkręty mocujące': 'Wkręty mocujące',
      'Domykacz': 'Domykacz',
      'Zaczep do drzwi drewnianych': 'Zaczep do drzwi drewnianych',
      'Zaczep do drzwi szklanych': 'Zaczep do drzwi szklanych',
      'Zaczep uniwersalny': 'Zaczep uniwersalny',
      'Podkładka': 'Podkładka',
      'Domykacz wyhamowuje drzwi w skrajnym położeniu i łagodnie je domyka. Do drzwi o wadze do 80 kg': 'Domykacz wyhamowuje drzwi w skrajnym położeniu i łagodnie je domyka. Do drzwi o wadze do 80 kg',
      'Uchwyt maskownicy': 'Uchwyt maskownicy',
      'Klamra ścienna 45': 'Klamra ścienna 45',
      'Klamra ścienna 25': 'Klamra ścienna 25',
      'Maskownica aluminiowa z zaślepkami bocznymi i szczotką przeciwkurzową': 'Maskownica aluminiowa z zaślepkami bocznymi i szczotką przeciwkurzową',
      'Linka stalowa – 8 m': 'Linka stalowa – 8 m',
      'Stoper z rolką': 'Stoper z rolką',
      'Wspornik montażowy ze sprężyną': 'Wspornik montażowy ze sprężyną',
      'Łącznik': 'Łącznik',
      'Prowadnica stalowa': 'Prowadnica stalowa',
      'Wózek': 'Wózek',
      'Stoper': 'Stoper',
      'Mocowanie prowadnicy': 'Mocowanie prowadnicy',
      'Ogranicznik górny': 'Ogranicznik górny',
      'Prowadnik dolny': 'Prowadnik dolny',
      'Komplet zaślepek bocznych': 'Komplet zaślepek bocznych',
      'Szczotka przeciwkurzowa': 'Szczotka przeciwkurzowa',

      //Home
      'Zobacz więcej': 'Zobacz więcej',
      'System przesuwny': 'System przesuwny',
      'Systemy przesuwne': 'Systemy przesuwne',
      'Systemy przesuwne do szkła': 'Systemy przesuwne do szkła',
      'Systemy do lekkich drzwi przesuwnych': 'Systemy do lekkich drzwi przesuwnych',
      'Systemy składane': 'Systemy składane',
      'Systemy do drzwi przesuwnych \n w szafach i zabudowach wnęk': 'Systemy do drzwi przesuwnych \n w szafach i zabudowach wnęk',
      'hero-copy-pura': 'Odkryj nowoczesność i elegancję dzięki naszemu systemowi drzwi przesuwnych z całkowicie ukrytą prowadnicą. Zaprojektowany, aby doskonale wpasować się w minimalistyczne wnętrza, oferuje płynne i ciche działanie. Innowacyjna konstrukcja, która sprawia, że prowadnica jest niewidoczna, zapewniając czysty i estetyczny wygląd. Dzięki zaawansowanej technologii drzwi przesuwają się niemal bezszelestnie. Wykonany z wysokiej jakości materiałów, gwarantuje długowieczność i niezawodność.',
      'hero-copy-s-series': 'To trzy systemy do drzwi przesuwnych: do 60 kg, 100 kg i 120 kg. Każdy z nich można mocować do stropu lub do ściany z zastosowaniem opcjonalnych klamer ściennych, wyposażyć w uchwyty maskownicy drewnianej, samodomykacze, a w przypadku systemów na 2 skrzydła drzwi w zestaw umożliwiający synchroniczne otwieranie. Wszystkie systemy serii S można wyposażyć w maskownicę aluminiową, pasującą do wnętrz o nowoczesnym charakterze.',
      'hero-copy-g-series': 'Posiadamy w naszej ofercie dwa systemy do szklanych drzwi przesuwnych. System G80 (obciążenie skrzydła drzwi do 80 kg) można zamocować do stropu lub opcjonalnie do ściany i wyposażyć w uchwyt maskownicy z płyty mdf. System G100 nie wymaga wiercenia otwórów w szkle, można go wyposażyć w maskownicę aluminiową, samodomykacze. Można go zamocować do stropu lub opcjonalnie do ścian.',
      'hero-copy-loft-series': 'Seria systemów przypominających swoim wyglądem klasyczne systemy do drzwi przesuwnych. Sprawdzą się we wnętrzach o wyrazistym charakterze nawiązującym do stylistyki loft lub atmosferze rustykalnej. Systemy występują z kilkoma wariantami wózków i w dwóch kolorach, czarnym i białym. Można je wyposażyć w komplet samodomykaczy a także uchwyty do drzwi w odpowiadającej stylistyce.',

      //Opisy
      'S60_description': '* Maks. obciążenie skrzydła drzwi 60 kg\n * Długość prowadnicy od 100 do 300 cm\n * Montaż do stropu lub do ściany\n * Wózki wyposażono w łożyska igiełkowe, które zapewniają bezawaryjną pracę do 100000 cykli otwierania i zamykania drzwi o wadze do 60 kg',
      'S100_description': '* Maks. obciążenie skrzydła drzwi 100 kg\n * Długość prowadnicy od 100 do 300 cm\n * Montaż do stropu lub do ściany\n * Wózki wyposażono w łożyska igiełkowe o podwyższonej wytrzymałości, które zapewniają bezawaryjną pracę do 100000 cykli otwierania i zamykania drzwi o wadze do 100 kg',
      'S120_description': '* Maks. obciążenie skrzydła drzwi 120 kg\n * Długość prowadnicy od 100 do 300 cm\n * Montaż do stropu lub do ściany\n * Wózki wyposażono w łożyska kulkowe o najwyższej wytrzymałości, które zapewniają bezawaryjną pracę do 100000 cykli otwierania i zamykania drzwi o wadze do 120 kg\n * Nylonowe kółka zapewniają cichą pracę',
      'S60_good_to_know': '* Opcje dostępne w konfiguratorze: klamry mocujące prowadnicę do ściany, domykacz, uchwyty maskownicy drewnianej, maskownica aluminiowa (w przypadku mocowania do ściany)\n * Zestaw nie zawiera drzwi',
      'S100_good_to_know': '* Opcje dostępne w konfiguratorze: klamry mocujące prowadnicę do ściany, domykacz, uchwyty maskownicy drewnianej, maskownica aluminiowa (w przypadku mocowania do ściany)\n * Zestaw nie zawiera drzwi',
      'S120_good_to_know': '* Opcje dostępne w konfiguratorze: klamry mocujące prowadnicę do ściany, domykacz, uchwyty maskownicy drewnianej, maskownica aluminiowa (w przypadku mocowania do ściany)\n * Zestaw nie zawiera drzwi',
      'S60_kit_description': '* Maks. obciążenie skrzydła drzwi 60 kg\n * Wózki wyposażono w łożyska igiełkowe, które zapewniają bezawaryjną pracę do 100000 cykli otwierania i zamykania drzwi o wadze do 60 kg',
      'S100_kit_description': '* Maks. obciążenie skrzydła drzwi 100 kg\n * Wózki wyposażono w łożyska igiełkowe o podwyższonej wytrzymałości, które zapewniają bezawaryjną pracę do 100000 cykli otwierania i zamykania drzwi o wadze do 100 kg',
      'S120_kit_description': '* Maks. obciążenie skrzydła drzwi 120 kg\n * Wózki wyposażono w łożyska kulkowe o najwyższej wytrzymałości, które zapewniają bezawaryjną pracę do 100000 cykli otwierania i zamykania drzwi o wadze do 120 kg\n * Nylonowe kółka zapewniają cichą pracę',
      '2S60_description': '* Maks. obciążenie skrzydła drzwi 60 kg\n * Długość prowadnicy od 140 do 300 cm\n * Montaż do stropu lub do ściany\n * Wózki wyposażono w łożyska igiełkowe, które zapewniają bezawaryjną pracę do 100000 cykli otwierania i zamykania drzwi o wadze do 60 kg',
      '2S100_description': '* Maks. obciążenie skrzydła drzwi 100 kg\n * Długość prowadnicy od 140 do 300 cm\n * Montaż do stropu lub do ściany\n * Wózki wyposażono w łożyska igiełkowe o podwyższonej wytrzymałości, które zapewniają bezawaryjną pracę do 100000 cykli otwierania i zamykania drzwi o wadze do 100 kg',
      '2S120_description': '* Maks. obciążenie skrzydła drzwi 120 kg\n * Długość prowadnicy od 140 do 300 cm\n * Montaż do stropu lub do ściany\n * Wózki wyposażono w łożyska kulkowe o najwyższej wytrzymałości, które zapewniają bezawaryjną pracę do 100000 cykli otwierania i zamykania drzwi o wadze do 120 kg\n * Nylonowe kółka zapewniają cichą pracę',
      '2S60_good_to_know': '* Opcje dostępne w konfiguratorze: klamry mocujące prowadnicę do ściany, domykacz, uchwyty maskownicy drewnianej, maskownica aluminiowa (w przypadku mocowania do ściany), mechanizm umożliwiający synchroniczne otwieranie i zamykanie 2 skrzydeł drzwiowych w przeciwnych kierunkach\n * Zestaw nie zawiera drzwi',
      '2S100_good_to_know': '* Opcje dostępne w konfiguratorze: klamry mocujące prowadnicę do ściany, domykacz, uchwyty maskownicy drewnianej, maskownica aluminiowa (w przypadku mocowania do ściany), mechanizm umożliwiający synchroniczne otwieranie i zamykanie 2 skrzydeł drzwiowych w przeciwnych kierunkach\n * Zestaw nie zawiera drzwi',
      '2S120_good_to_know': '* Opcje dostępne w konfiguratorze: klamry mocujące prowadnicę do ściany, domykacz, uchwyty maskownicy drewnianej, maskownica aluminiowa (w przypadku mocowania do ściany), mechanizm umożliwiający synchroniczne otwieranie i zamykanie 2 skrzydeł drzwiowych w przeciwnych kierunkach\n * Zestaw nie zawiera drzwi',
      'RO-S60_description': '* Maks. obciążenie skrzydła drzwi do 60 kg (na 2 wózkach)',
      'RO-S100_description': '* Maks. obciążenie skrzydła drzwi do 100 kg (na 2 wózkach)',
      'RO-S120_description': '* Maks. obciążenie skrzydła drzwi do 120 kg (na 2 wózkach)',

      'LFT-B_description': '* Maks. obciążenie skrzydła drzwi 100 kg\n * Montaż prowadnicy do ściany\n * Długość prowadnicy 200 cm\n * Wykończenie czarny mat',
      'LFT-B_good_to_know': '* Grubość drzwi 35-45 mm\n * Materiał drzwi: Drewno, sklejka, płyta wiórowa, płyta mdf, płyta komórkowa\n * Liczba skrzydeł drzwiowych: 1\n * Zestaw nie zawiera drzwi',
      'G100_description': '* Maks. obciążenie skrzydła drzwi 100 kg\n * Długość prowadnicy od 100 do 300 cm\n * Montaż do stropu lub do ściany\n * Nie wymaga wiercenia otworów w szkle\n * Wózki wyposażono w łożyska kulkowe o najwyższej wytrzymałości, które zapewniają bezawaryjną pracę do 100000 cykli otwierania i zamykania drzwi o wadze do 100 kg\n * Nylonowe kółka zapewniają cichą pracę',
      'G100_kit_description': '* Maks. obciążenie skrzydła drzwi 100 kg\n * Nie wymaga wiercenia otworów w szkle\n * Wózki wyposażono w łożyska kulkowe o najwyższej wytrzymałości, które zapewniają bezawaryjną pracę do 100000 cykli otwierania i zamykania drzwi o wadze do 100 kg\n * Nylonowe kółka zapewniają cichą pracę',
      'G100_good_to_know': '* Opcje dostępne w konfiguratorze: klamry mocujące prowadnicę do ściany, domykacz, maskownica aluminiowa (w przypadku mocowania do ściany)\n * Zestaw nie zawiera drzwi',
      'G80_description': '* Maks. obciążenie skrzydła drzwi 80 kg\n * Długość prowadnicy od 100 do 300 cm\n * Montaż do stropu lub do ściany\n * Uwaga. Wymagane jest wywiercenie otworów w szkle\n * Wózki wyposażono w łożyska igiełkowe o podwyższonej wytrzymałości, które zapewniają bezawaryjną pracę do 100000 cykli otwierania i zamykania drzwi o wadze do 80 kg',
      'G80_kit_description': '* Maks. obciążenie skrzydła drzwi 80 kg\n * Uwaga. Wymagane jest wywiercenie otworów w szkle\n * Wózki wyposażono w łożyska igiełkowe o podwyższonej wytrzymałości, które zapewniają bezawaryjną pracę do 100000 cykli otwierania i zamykania drzwi o wadze do 80 kg',
      'G80_good_to_know': '* Opcje dostępne w konfiguratorze: klamry mocujące prowadnicę do ściany, uchwyty maskownicy drewnianej\n * Zestaw nie zawiera drzwi',
      'LFT-SCD_description': '* Umożliwiają płynne zamykanie drzwi\n * Przeznaczone do systemów Loft\n * Komplet 2 szt.',

      'DS40_description': '* Zapewnia łagodne domykanie i zatrzymywanie drzwi\n * Mechanizm w całości ukryty w prowadnicy\n * Domykacz może być zamontowany na obu końcach prowadnicy\n * Ciężar skrzydła drzwi do 45 kg\n * Do zastosowania z systemem do drzwi przesuwnych W40',
      'DS40_good_to_know': '* **Elementy wchodzące w skład zestawu:** \n * Domykacz ×1\n * Mocowanie domykacza ×2\n * Zaczep drzwi ×1\n * Wkręty do drewna ×1 kpl.',

      'SC-SG_description': '* Wyhamowuje drzwi w skrajnym położeniu i łagodnie je domyka\n * Może być zamontowany na obu końcach prowadnicy\n * Niewidoczny montaż w środku prowadnicy\n * 3-stopniowa regulacja siły domykania (40 kg, 60 kg, 80 kg)\n * Do drzwi o wadze do 80 kg\n * Można zastosować do istniejącej instalacji systemu Tycho\n * Do zastosowania z systemami do drzwi przesuwnych S120, G100',
      'SCD-S_description': '* Wyhamowuje drzwi w skrajnym położeniu i łagodnie je domyka\n * Może być zamontowany na obu końcach prowadnicy\n * Niewidoczny montaż w środku prowadnicy\n * 3-stopniowa regulacja siły domykania (40 kg, 60 kg, 80 kg)\n * Do drzwi o wadze do 80 kg\n * Można zastosować do istniejącej instalacji systemu Tycho\n * Do zastosowania z systemami do drzwi przesuwnych S60, S100',
      '2S_description': '* Mechanizm umożliwia synchroniczne otwieranie i zamykanie 2 skrzydeł drzwiowych w przeciwnych kierunkach\n * Do zastosowania z systemami S60, S100, S120\n * Maks. obciążenie skrzydła drzwi 60 kg\n * Do drzwi drewnianych\n * Maksymalna szerokość otwarcia 4 m',

      'L30_description': '* Bardzo cichy i lekki przesuw drzwi dzięki łożyskowanym kółkom wózków\n * Tor jezdny o długości od 100 do 240 cm\n * Drzwi do 30 kg\n * Łatwy montaż bez konieczności sięgania po specjalistyczne narzędzia\n * Montaż toru jezdnego do sufitu lub do ściany',
      'L40_description': '* Bardzo cichy i lekki przesuw drzwi dzięki łożyskowanym kółkom wózków\n * Tor jezdny o długości od 100 do 240 cm\n * Drzwi do 40 kg\n * Łatwy montaż bez konieczności sięgania po specjalistyczne narzędzia\n * Montaż toru jezdnego do sufitu lub do ściany\n * Łatwa i dokładna regulacja ustawienia drzwi\n * Montaż wózków na płaszczyźnie drzwi jest szczególnie polecany w przypadku drzwi z płyty wiórowej. Zapewnia większą trwałość i wytrzymałość mocowania niż w przypadku montażu wózków do górnej krawędzi drzwi.',

      'W40_description': '* Bardzo cichy i lekki przesuw drzwi dzięki łożyskowanym kółkom wózków\n * Tor jezdny o długości od 100 do 240 cm\n * Drzwi do 45 kg\n * Łatwy montaż bez konieczności sięgania po specjalistyczne narzędzia\n * Niski tor jezdny\n * Montaż toru jezdnego do sufitu\n * Możliwość zamontowania domykacza',
      'W40_kit_description': '* Bardzo cichy i lekki przesuw drzwi dzięki łożyskowanym kółkom wózków\n * Drzwi do 45 kg\n * Łatwy montaż bez konieczności sięgania po specjalistyczne narzędzia',
      'W40_good_to_know': '* Materiał drzwi: Drewno, sklejka, płyta wiórowa, płyta mdf, płyta komórkowa\n * Grubość drzwi: od 16 do 40 mm\n * System na 2 skrzydła. Prowadnica 100—180 cm\n * System na 3 skrzydła. Prowadnica 181—240 cm\n * Zestaw nie zawiera drzwi',
      'W40_kit_good_to_know': '* Materiał drzwi: Drewno, sklejka, płyta wiórowa, płyta mdf, płyta komórkowa\n * Grubość drzwi: od 16 do 40 mm\n * Zestaw nie zawiera drzwi',

      'W50_description': '* Bardzo cichy i lekki przesuw drzwi dzięki łożyskowanym wózkom\n * Drzwi do 50 kg\n * Do szaf i wnęk\n * Możliwość zamontowania domykaczy\n * Tory jezdne o dł. 200 lub 300 cm, które można dociąć na wymiar\n * Pochwyty o wysokości 270 cm, które można dociąć na wymiar',
      'W50_kit_description': '* Bardzo cichy i lekki przesuw drzwi dzięki łożyskowanym wózkom\n * Drzwi do 50 kg\n * Maksymalna wysokość: 274 cm',
      'W50_good_to_know': '* Materiał drzwi: Drewno, sklejka, płyta wiórowa, płyta mdf, płyta komórkowa, szkło, lustro\n * Grubość drzwi: płyta 18 mm lub lustro/szkło 4-6 mm\n * Prowadnica 200 cm: System na 2 skrzydła\n * Prowadnica 300 cm: System na 3 skrzydła\n * Maksymalna wysokość zabudowy: 274 cm\n * Zestaw nie zawiera drzwi',

      'W60_description': '* System do drzwi przesuwnych w szafach i zabudowach wnęk\n * Tory jezdne o długości od 100 do 240 cm\n * Bardzo cichy i lekki przesuw drzwi dzięki łożyskowanym wózkom\n * Łatwy montaż bez konieczności sięgania po specjalistyczne narzędzia\n * Bardzo niskie tor jezdne – tylko 8 mm różnicy między wysokością skrzydła drzwiowego i otworu\n * Maks. ciężar skrzydła drzwi 70 kg',
      'W60_kit_description': '* System do drzwi przesuwnych w szafach i zabudowach wnęk\n * Bardzo cichy i lekki przesuw drzwi dzięki łożyskowanym wózkom\n * Łatwy montaż bez konieczności sięgania po specjalistyczne narzędzia\n * Maks. ciężar skrzydła drzwi 70 kg',
      'W60_good_to_know': '* Materiał drzwi: Drewno, sklejka, płyta wiórowa, płyta mdf, płyta komórkowa\n * Grubość drzwi od 16 mm\n * Możliwość rozsunięcia torów jezdnych i użycia drzwi dowolnej grubości\n * System na 2 skrzydła. Prowadnica 100—180 cm\n * System na 3 skrzydła. Prowadnica 181—240 cm\n * Zestaw nie zawiera drzwi',
      'W60_kit_good_to_know': '* Materiał drzwi: Drewno, sklejka, płyta wiórowa, płyta mdf, płyta komórkowa\n * Grubość drzwi od 16 mm\n * Zestaw nie zawiera drzwi',

      'FSC_925_description': '* Do stosowania z systemami S60, S100, S120, G100\n * Osłona mocowana na szynę, montaż naścienny\n * Grubość drzwi do 25 mm\n * Wysokość: 10 cm\n * Ze szczotką chroniącą przed kurzem',
      'FSC_925_good_to_know': '* UWAGA: Długość w konfiguratorze odpowiada długości prowadnicy (maskownica jest dłuższa o 1 cm)',
      'FSC_945_description': '* Do stosowania z systemami S60, S100, S120\n* Osłona mocowana na szynę, montaż naścienny\n * Grubość drzwi do 45 mm\n * Wysokość: 10 cm\n * Ze szczotką chroniącą przed kurzem',
      'FSC_945_good_to_know': '* UWAGA: Długość w konfiguratorze odpowiada długości prowadnicy (maskownica jest dłuższa o 1 cm)',
      'FSC_980_description': '* Do stosowania z systemami S60, S100, S120, G100\n * Możliwość montażu na prowadnicy mocowanej do ściany lub do sufitu',
      'FSC_980_good_to_know': '* W przypadku montażu prowadnicy do ściany za pomocą klamer ściennych minimalna odległość między górną płaszczyzną prowadnicy a sufitem wynosi 15 mm\n * W przypadku montażu prowadnicy do sufitu należy zapewnić dystans 15 mm między sufitem a górną płaszczyzną prowadnicy. Wymagany dystans najlepiej uzyskać stosując tulejki dystansowe',

      'FSC_937_description': '* Do stosowania z systemami S60, S100, S120, G100\n * Osłona mocowana na szynę, montaż naścienny\n * Grubość drzwi do 25 mm\n * Wysokość: 10 cm\n * Ze szczotką chroniącą przed kurzem',
      'FSC_937_good_to_know': '* UWAGA: Długość w konfiguratorze odpowiada długości prowadnicy (maskownica jest dłuższa o 1 cm)',
      'FSC_957_description': '* Do stosowania z systemami S60, S100, S120\n* Osłona mocowana na szynę, montaż naścienny\n * Grubość drzwi do 45 mm\n * Wysokość: 10 cm\n * Ze szczotką chroniącą przed kurzem',
      'FSC_957_good_to_know': '* UWAGA: Długość w konfiguratorze odpowiada długości prowadnicy (maskownica jest dłuższa o 1 cm)',
      'FSC_992_description': '* Do stosowania z systemami S60, S100, S120, G100\n * Możliwość montażu na prowadnicy mocowanej do ściany lub do sufitu',
      'FSC_992_good_to_know': '* W przypadku montażu prowadnicy do ściany za pomocą klamer ściennych minimalna odległość między górną płaszczyzną prowadnicy a sufitem wynosi 15 mm\n * W przypadku montażu prowadnicy do sufitu należy zapewnić dystans 15 mm między sufitem a górną płaszczyzną prowadnicy. Wymagany dystans najlepiej uzyskać stosując tulejki dystansowe',

      'SPCR_description': '* Zapewnia dystans pomiędzy prowadnicą a sufitem, niezbędny do założenia płaskiej maskownicy aluminiowej\n * Do prowadnicy S mocowanej do sufitu\n * Do systemów S60, S100, S120, G100',

      'F25_description': '* Maksymalne obciążenie pary drzwi 25 kg\n * Długość prowadnicy od 80 do 200 cm\n * Montaż do stropu',
      'F40_description': '* Maksymalne obciążenie pary drzwi 40 kg\n * Długość prowadnicy od 80 do 200 cm\n * Montaż do stropu',
      'F25_good_to_know': '* Materiał drzwi: Drewno, sklejka, płyta wiórowa, płyta mdf, płyta komórkowa\n * Grubość drzwi od 16 do 40 mm\n * Opcje dostępne w konfiguratorze: uchwyty maskownicy drewnianej\n * Zestaw nie zawiera drzwi',
      'F40_good_to_know': '* Materiał drzwi: Drewno, sklejka, płyta wiórowa, płyta mdf, płyta komórkowa\n * Grubość drzwi od 16 do 40 mm\n * Opcje dostępne w konfiguratorze: uchwyty maskownicy drewnianej\n * Zestaw nie zawiera drzwi',
      'F25_kit_description': '* Maksymalne obciążenie pary drzwi 25 kg\n * Zestaw na 1 parę drzwi',
      'F40_kit_description': '* Maksymalne obciążenie pary drzwi 40 kg\n * Zestaw na 1 parę drzwi',
      'RO-F25_description': '* Obciążenie maksymalne 25 kg',
      'RO-F40_description': '* Obciążenie maksymalne 40 kg',

      'WFB_UNI_description': '* Umożliwia montaż maskownicy drewnianej, z płyty mdf, lub drewnopodobnej\n * Do stosowania z systemami G80, F25, F40',
      'WFB_S_description': '* Umożliwia montaż maskownicy drewnianej, z płyty mdf, lub drewnopodobnej\n * Do stosowania z systemami S60, S100, S120, G100',
      'WFB_F_description': '* Umożliwia montaż maskownicy drewnianej, z płyty mdf, lub drewnopodobnej\n * Do stosowania z systemami F25, F40',
      'CB_25_UNI_description': '* Klamra ścienna łącząca dwie prowadnice mocowane na ścianie\n * Do stosowania z systemem G80\n * Maksymalna grubość drzwi 25 mm',
      'CB_25_S_description': '* Klamra ścienna łącząca dwie prowadnice mocowane na ścianie\n * Do stosowania z systemami S60, S100, S120, G100\n * Maksymalna grubość drzwi 25 mm',
      'CB_45_S_description': '* Klamra ścienna łącząca dwie prowadnice mocowane na ścianie\n * Do stosowania z systemami S60, S100, S120\n * Maksymalna grubość drzwi 45 mm',
      'WB_25_S_description': '* Klamra ścienna umożliwia montaż prowadnicy na ścianie\n * Do stosowania z systemami S60, S100, S120, G100\n * Maksymalna grubość drzwi 25 mm',
      'WB_45_S_description': '* Klamra ścienna umożliwia montaż prowadnicy na ścianie\n * Do stosowania z systemami S60, S100, S120\n * Maksymalna grubość drzwi 45 mm',
      'WB_25_UNI_description': '* Klamra ścienna umożliwia montaż prowadnicy na ścianie\n * Do stosowania z systemem G80\n * Maksymalna grubość drzwi 25 mm',

      'LFT-HND-01_description': '* Do drzwi drewnianych\n * Wysokość 20 cm\n * 1 szt.',
      'LFT-HND-02_description': '* Do drzwi drewnianych\n * Wysokość 32 cm\n * 1 szt.',
      'LFT-HND-03_description': '* Do drzwi drewnianych\n * Wysokość 28 cm\n * 1 szt.',
      'GHR-058_description': '* Do drzwi przesuwnych szklanych\n * Do szkła o grubości od 8 do 12 mm\n * Wykończenie: stal satynowana',
      'GHR-058-BM_description': '* Do drzwi przesuwnych szklanych\n * Do szkła o grubości od 8 do 12 mm\n * Wykończenie: czarny mat',
      'TRC_F_description': '* Do stosowania z systemami F25, F40',
      'TRC_S_description': '* Do stosowania z systemami S60, S100, S120, G100',
      'TRC_UNI_description': '* Do stosowania z systemami G80, F25, F40',
      'LG-01_description': '* Z przezroczystego tworzywa',
      'LG-02_description': '* Z przezroczystego tworzywa',

      'PURA_description': '* Odkryj nowoczesność i elegancję dzięki naszemu systemowi drzwi przesuwnych z całkowicie ukrytą prowadnicą. Zaprojektowany, aby doskonale wpasować się w minimalistyczne wnętrza, oferuje płynne i ciche działanie.\n * Innowacyjna konstrukcja, która sprawia, że prowadnica jest niewidoczna, zapewniając czysty i estetyczny wygląd.\n * Dzięki zaawansowanej technologii drzwi przesuwają się niemal bezszelestnie.\n * Wykonany z wysokiej jakości materiałów, gwarantuje długowieczność i niezawodność.',
      'PURA_good_to_know': '* System przeznaczony jest do drewnianych drzwi przesuwnych\n * Maksymalna waga drzwi 60 kg\n * Szerokość drzwi 80-120 cm\n * Grubość drzwi 28-45 mm\n * Montaż do ściany\n * Zestaw nie zawiera drzwi',
      'PURA_bullet_1': 'Maksymalna waga drzwi 80 kg',
      'PURA_bullet_2': 'Szerokość skrzydła drzwi 80-120 cm',
      'PURA_bullet_3': 'Montaż do ściany',

      //Opisy Rysunkow
      'Rys.': 'Rys.',
      'ceiling_mounted': 'Mocowanie do stropu',
      'wall_mounted': 'Mocowanie do ściany za pomocą opcjonalnych klamer ściennych',
      'wall_mounted_25': 'Mocowanie do ściany za pomocą opcjonalnych klamer ściennych 25',
      'wall_mounted_45': 'Mocowanie do ściany za pomocą opcjonalnych klamer ściennych 45',
      'wall_mounted_25_wooden_fascia': 'Mocowanie do ściany za pomocą opcjonalnych klamer ściennych 25 i z maskownicą drewnianą',
      'wall_mounted_45_wooden_fascia': 'Mocowanie do ściany za pomocą opcjonalnych klamer ściennych 45 i z maskownicą drewnianą',
      'wall_mounted_25_aluminium_fascia': 'Mocowanie do ściany za pomocą opcjonalnych klamer ściennych 25 i z maskownicą aluminiową 25',
      'wall_mounted_45_aluminium_fascia': 'Mocowanie do ściany za pomocą opcjonalnych klamer ściennych 45 i z maskownicą aluminiową 45',


      //Super krotki opis
      'Drzwi do 25 kg': 'Drzwi do 25 kg',
      'Drzwi do 40 kg': 'Drzwi do 40 kg',
      'Drzwi do 45 kg': 'Drzwi do 45 kg',
      'Drzwi do 60 kg': 'Drzwi do 60 kg',
      'Drzwi do 70 kg': 'Drzwi do 70 kg',
      'Drzwi do 80 kg': 'Drzwi do 80 kg',
      'Drzwi do 100 kg': 'Drzwi do 100 kg',
      'Drzwi do 120 kg': 'Drzwi do 120 kg',
      'Prowadnica 80–200 cm': 'Prowadnica 80–200 cm',
      'Prowadnica 100–300 cm': 'Prowadnica 100–300 cm',
      'Prowadnica 160–300 cm': 'Prowadnica 160–300 cm',
      'Prowadnica 200 cm': 'Prowadnica 200 cm',
      'Montaż do stropu lub ściany': 'Montaż do stropu lub ściany',
      'Synchroniczne otwieranie 2 drzwi': 'Synchroniczne otwieranie 2 drzwi',
      'Do S60, S100, S120': 'Do S60, S100, S120',
      'Łagodnie domyka drzwi': 'Łagodnie domyka drzwi',
      'Niewidoczny montaż': 'Niewidoczny montaż',
      'Do drzwi przesuwnych szklanych': 'Do drzwi przesuwnych szklanych',
      'Grubość szkła 8–12 mm': 'Grubość szkła 8–12 mm',
      'Montaż do ściany': 'Montaż do ściany',
      'Montaż do stropu': 'Montaż do stropu',
      'Niski tor jezdny': 'Niski tor jezdny',

      'Mocowana na szynę montowaną naściennie': 'Mocowana na szynę montowaną naściennie',
      'Do systemów S60, S100, S120, G80, G100': 'Do systemów S60, S100, S120, G80, G100',
      'Do systemów G80, G100': 'Do systemów G80, G100',
      'Do systemów S60, S100, S120': 'Do systemów S60, S100, S120',
      'Grubość drzwi do 25 mm': 'Grubość drzwi do 25 mm',
      'Grubość drzwi do 45 mm': 'Grubość drzwi do 45 mm',
      'Na dodatkowe skrzydło drzwi': 'Na dodatkowe skrzydło drzwi',
      'Komplet 2 szt.': 'Komplet 2 szt.',
      'Do drzwi drewnianych': 'Do drzwi drewnianych',
      'Wysokość 28 cm': 'Wysokość 28 cm',
      'Wysokość 32 cm': 'Wysokość 32 cm',
      'Obciążenie do 60 kg (na 2 wózkach)': 'Obciążenie do 60 kg (na 2 wózkach)',
      'Obciążenie do 100 kg (na 2 wózkach)': 'Obciążenie do 100 kg (na 2 wózkach)',
      'Obciążenie do 120 kg (na 2 wózkach)': 'Obciążenie do 120 kg (na 2 wózkach)',
      'Obciążenie do 25 kg': 'Obciążenie do 25 kg',
      'Obciążenie do 40 kg': 'Obciążenie do 40 kg',
      'Z przezroczystego tworzywa': 'Z przezroczystego tworzywa',
      'Do prowadnicy mocowanej do sufitu': 'Do prowadnicy mocowanej do sufitu',
      'Łączy dwie prowadnice mocowane na ścianie': 'Łączy dwie prowadnice mocowane na ścianie',
      'Umożliwia montaż prowadnicy na ścianie': 'Umoliwia montaż prowadnicy na ścianie',
      'Umożliwia montaż maskownicy drewnianej': 'Umożliwia montaż maskownicy drewnianej',

      //Manuals
      'PURA_manual': 'Instrukcja montażu systemu Pura',
      'L30_manual': 'Instrukcja montażu systemu L30',
      'L40_manual': 'Instrukcja montażu systemu L40',
      'W60_manual': 'Instrukcja montażu systemu W60',
      'W40_manual_2': 'Instrukcja montażu systemu W40 do szafy dwudrzwiowej',
      'W40_manual_3': 'Instrukcja montażu systemu W40 do szafy trzydrzwiowej',
      'DS40_manual': 'Instrukcja montażu domykacza',
      'W50_manual_2': 'Instrukcja montażu systemu W50 do szafy dwudrzwiowej',
      'W50_manual_3': 'Instrukcja montażu systemu W50 do szafy trzydrzwiowej',

      'F25_manual': 'Instrukcja montażu systemu F25',
      'F40_manual': 'Instrukcja montażu systemu F40',
      'S60_manual': 'Instrukcja montażu systemu S60',
      'S100_manual': 'Instrukcja montażu systemu S100',
      'S120_manual': 'Instrukcja montażu systemu S120',
      'G80_manual': 'Instrukcja montażu systemu G80',
      'G100_manual': 'Instrukcja montażu systemu G100',
      'WB_45_S_manual': 'Instrukcja montażu klamry ściennej 45 S',
      'WB_25_S_manual': 'Instrukcja montażu klamry ściennej 25 S',
      'WB_40_UNI_manual': 'Instrukcja montażu klamry ściennej 40 UNI',
      'WB_25_UNI_manual': 'Instrukcja montażu klamry ściennej 25 UNI',
      'WFB_UNI_manual': 'Instrukcja montażu uchwytu maskownicy drewnianej',
      'WFB_S_manual': 'Instrukcja montażu uchwytu maskownicy drewnianej',
      'WFB_F_manual': 'Instrukcja montażu uchwytu maskownicy drewnianej',
      'SCD-S_manual': 'Instrukcja montażu domykacza',
      'SC-SG_manual': 'Instrukcja montażu domykacza',
      '2S_manual': 'Instrukcja montażu mechanizmu synchronicznego otwierania i zamykania drzwi',
      'FSC925_manual': 'Instrukcja montażu maskownicy aluminiowej z zaślepkami bocznymi 25 mm',
      'FSC945_manual': 'Instrukcja montażu maskownicy aluminiowej z zaślepkami bocznymi 45 mm',
      'FSC980_manual': 'Instrukcja montażu maskownicy aluminiowej płaskiej',

      //Checkout
      'Creating system': 'Twój spersonalizowany system tworzony jest w bazie danych.',
      'Redirecting': 'Za chwilę nastąpi przekierowanie do kasy.',
      'Please be patient': 'Prosimy o cierpliwość.',


}