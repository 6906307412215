module.exports = {
    'ContactBarInfo1': 'Haben Sie Fragen? Rufen Sie uns an:',
    'ContactBarInfo2': '(Mo-Fr 8-16) oder auch unter:',
    'ContactBarInfo3': 'Schneller Kontakt:',
    'ContactBarInfo4': '(Mo-Fr 8-16). E-mail:',

    'Dodaj do koszyka': 'In den Warenkorb',
    'Koszyk': 'Warenkorb',
    'Twój koszyk jest pusty.': 'Ihr Warenkorb ist leer.',
    'Wartość koszyka': 'Gesamt',
    'Przejdź do kasy': 'Zur Kasse gehen',
    'Znaleziono': 'Gefunden',
    'Szukaj produktów': 'Suche nach Produkten',
    'Ceny: od': 'Nach Preis sortiert:',
    'od ': 'ab ',
    'najwyższej': 'hoch zu niedrig',
    'najniższej': 'niedrig zu hoch',
    'loading': 'loading',

    'Strona Główna': 'Startseite',
    'Produkty': 'Produkte',
    'Seria F': 'F-Serie',
    'Seria G': 'G-Serie',
    'Seria L': 'L-Serie',
    'Seria Loft': 'Loft-Serie',
    'Seria S': 'S-Serie',
    'Seria W': 'W-Serie',

    'Zawartość zestawu': 'Das Kit enthält',
    'Główne cechy': 'Haupteigenschaften',
    'Gwarancja, zwroty, dostawa': 'Garantie, Rückgabe, Lieferung',
    'Przydatne informacje': 'Praktische Infos',
    'Rysunki techniczne': 'Technische Zeichnungen',
    'Do pobrania': 'Downloads',
    'Może Ci się spodobać': 'Empfohlene Produkte',

    'warranty_info': '5 Jahre Garantie auf alle Komponenten.',
    'return_info': 'Sie haben das Recht, Ihren Kauf innerhalb von 30 Tagen zu stornieren.',
    'shipping_info_0': 'Versand ',
    'shipping_info_1': 'Versand 4.70 €',
    'shipping_info_2': 'Lieferzeit 3 bis 9 Tage',
    'shipping_info_2B': 'Lieferzeit 2 bis 7 Tage',
    'shipping_info_3': '',

    //Menu
    'Systemy do drzwi przejściowych': 'Durchgangstüren',
    'Systemy do drzwi w szafach': 'Schranktüren',
    'Seria S – przesuwne': 'S-Serie – Schiebesysteme',
    'Seria G – przesuwne do szkła': 'G-Serie – Schiebesysteme für Glastür',
    'Seria Loft – przesuwne': 'Loft-Serie – Schiebesysteme',
    'Seria F – składane': 'F-Serie – Faltsysteme',
    'Seria W – przesuwne': 'W-Serie – Schiebesysteme',
    'Seria L – przesuwne lekkie': 'L Series – Schiebesysteme für leichte Türen',
    'Akcesoria': "Zubehör",
    'Akcesoria G100': "Zubehör",
    'Akcesoria Loft': "Zubehör",
    'Systemy Loft': "Loft Systeme",

    // Konfigurator 
    'Wariant': 'Variante',
    'Produkt': 'Produkt',
    'Prowadnica': 'Schiene',
    'Długość': 'Länge',
    'Mocowanie': 'Montage',
    'Do stropu': 'Decke',
    'Do ściany': 'Wand',
    'Grubość drzwi': 'Türstärke',
    'Maskownica aluminiowa': 'Aluminium-Blende',
    'Maskownica aluminiowa płaska': 'Flache Aluminium-Blende',
    'Maskownica płaska': 'Flache Blende',
    'Maskownica alu.': 'Aluminium-Blende',
    'Uchwyt maskownicy drewnianej': 'Holzblende-Montagewinkel',
    'Uchwyt maskow. drewn.': 'Holzblende-Montagewinkel',
    'Tak': 'Ja',
    'Nie': 'Nein',
    'Srebrna': 'Silber',
    'Czarna': 'Schwarz',
    'Miękkie domykanie': 'Türschließdämpfer',
    '1 strona': '1 Ende',
    '2 strony': '2 Enden',
    "Otwieranie synchroniczne": 'Synchronisationsbausatz',
    "Tuleja dystansowa": 'Abstandshalter',

    // Zawartosc zestawu 
    'Wózek z materiału o wysokiej wytrzymałości — poliacetalu. Osie kół ze stali łożyskowej w łożyskach kulkowych': 'Laufwagen aus hochbeständigem Werkstoff – Polyoxymethylen',
    'Wózek z materiału o wysokiej wytrzymałości — poliacetalu. Osie kół ze stali łożyskowej w łożyskach igiełkowych': 'Laufwagen aus hochbeständigem Werkstoff – Polyoxymethylen',
    'Prowadnica aluminiowa z otworami pod wkręty Ø 4 mm': 'Aluminiumschiene mit Bohrungen Ø4 mm. Kann auf die gewünschte Länge zugeschnitten werden',
    'Stoper blokujący drzwi w krańcowych położeniach': 'Fangstopper',
    'Stoper odbijający': 'Stopper',
    'Mocowanie szkła': 'Glashalter',
    'Wkładki elastomerowe do zacisków, dla różnej grubość szkła': 'Kunststoffstreifen',
    'Samoprzylepne uchwyty do drzwi z bezbarwnego tworzywa': 'Selbstklebender Türgriff',
    'Prowadnik dolny wykonany z bezbarwnego tworzywa': 'Bodenführung',
    'Klucz imbusowy': 'Sechskantschlüssel',
    'Komplet kluczy imbusowych': 'Inbusschlüsselsatz',
    'Obsada czopu górnego': 'Top Trunnion',
    'Czop górny': 'Top Pin',
    'Czop dolny': 'Bottom Pin',
    'Obsada czopu dolnego': 'Bottom Trunnion',
    'Zawias': 'Scharnier',
    'Wkręty montażowe': 'Montageschrauben',
    'Wkręty mocujące': 'Montageschrauben',
    'Domykacz': 'Türschließdämpfer',
    'Zaczep do drzwi drewnianych': 'Haken für Holztür',
    'Zaczep do drzwi szklanych': 'Haken für Glastür',
    'Zaczep uniwersalny': 'Mehrzweckhaken',
    'Podkładka': 'Unterstützung',
    'Domykacz wyhamowuje drzwi w skrajnym położeniu i łagodnie je domyka. Do drzwi o wadze do 80 kg': 'Türschließdämpfer. Der Dämpfer fängt die Tür auf, führt sie langsam in die geschlossene. Für Türen bis 80 kg',
    'Uchwyt maskownicy': 'Holzblende-Montagewinkel',
    'Klamra ścienna 45': 'Montagewinkel 45',
    'Klamra ścienna 25': 'Montagewinkel 25',
    'Maskownica aluminiowa z zaślepkami bocznymi i szczotką przeciwkurzową': 'Eloxierte Aluminiumblende mit Kappen und Staubschutzbürste',
    'Linka stalowa – 8 m': 'Kabel – 8 m',
    'Stoper z rolką': 'Umlenkrolle',
    'Wspornik montażowy ze sprężyną': 'Befestigungs-winkel mit Feder',
    'Łącznik': 'Mitnehmerwinkel',
    'Prowadnica stalowa': 'Stahllaufschiene',
    'Wózek': 'Laufwagen',
    'Stoper': 'Stopper',
    'Mocowanie prowadnicy': 'Wandhülsen',
    'Ogranicznik górny': 'Oberer Stopper',
    'Prowadnik dolny': 'Untere Führung',
    'Komplet zaślepek bocznych': 'Satz Endkappen',
    'Szczotka przeciwkurzowa': 'Staubschutzbürste',

    //Home
    'Zobacz więcej': 'Mehr',
    'System przesuwny': 'Schiebesystem',
    'Systemy przesuwne': 'Schiebesysteme',
    'Systemy przesuwne do szkła': 'Glastür Lösungen',
    'Systemy do lekkich drzwi przesuwnych': 'Systeme für Leichte Schiebetüren',
    'Systemy składane': 'Faltsysteme',
    'Systemy do drzwi przesuwnych \n w szafach i zabudowach wnęk': 'Schiebesysteme für Schranktüren',
    'hero-copy-pura': 'Entdecken Sie Modernität und Eleganz mit unserem Schiebetürsystem mit komplett versteckter Schiene. Es wurde so konzipiert, dass es perfekt in minimalistische Innenräume passt und einen reibungslosen und leisen Betrieb bietet. Ein innovatives Design, das die Führung unsichtbar macht und so für ein sauberes und ästhetisches Erscheinungsbild sorgt. Dank fortschrittlicher Technologie gleiten die Türen nahezu geräuschlos. Es besteht aus hochwertigen Materialien und garantiert Langlebigkeit und Zuverlässigkeit.',
    'hero-copy-s-series': 'Drei Systeme für Schiebetüren in den Varianten bis zu 60 kg, 100 kg und 120 kg. Sie können an jeder Decke oder an der Wand befestigt werden. Hierzu können optional Wandhalterungen verwendet werden, die mit Maskengriffen aus Holz und Selbstschließvorrichtungen ausgestattet sind. Systeme mit 2 Türflügeln können mit einem Set, das ein synchrones Öffnen ermöglicht ausgestattet werden. Darüber hinaus kann das System S120 mit einem Abdeckrahmen aus Aluminium versehen werden, welcher sich ideal für moderne Innenräume eignet.',
    'hero-copy-g-series': 'Unser Angebot enthält zwei verschiedene Systeme für Glasschiebetüren. Das G80-System (Türflügellast bis zu 80 kg) kann an der Decke oder optional an der Wand befestigt und mit einem Griffhalter aus MDF-Platte ausgestattet werden. Das G100-System erfordert keine Bohrungen im Glas, es kann mit einer selbstschließenden Aluminiumblende ausgestattet werden. Auch hier kann die Montage an Decke oder Wand erfolgen.',
    'hero-copy-loft-series': 'Eine Serie von Systemen, die optisch an klassische Schiebetürsysteme erinnern. Sie eignen sich vor allem für Innenräume mit unverwechselbarem Charakter, welche den Loft-Stil oder ein rustikales Ambiente unterstreichen. Diese Systeme werden mit mehreren Wagenvarianten geliefert und sind in den Farben schwarz und weiß erhältlich. Sie können mit einem Satz von Selbstschließern sowie Türgriffen in passendem Stil ausgestattet werden.',

    //Opisy
    'S60_description': '* Max. Türgewicht 60 kg\n* Schienenlänge ab 100 bis 200 cm\n* Decken- oder Wandmontage\n* Die Wagen sind mit Nadellagern ausgestattet, die einen störungsfreien Betrieb von bis zu 100.000 Zyklen zum Öffnen und Schließen von Türen mit einem Gewicht bis zu 60 kg gewährleisten',
    'S60_good_to_know': '* Optionen im Konfigurator verfügbar: Montagewinkel, Türschließdämpfer, Holzblende-Montagewinkel oder Aluminiumblende mit Kappen (bei Wandmontage möglich)\n* Türen nicht im Lieferumfang enthalten',
    'S100_description': '* Max. Türgewicht 100 kg\n* Schienenlänge ab 100 bis 200 cm\n* Decken- oder Wandmontage\n* Die Wagen sind mit Nadellagern ausgestattet, die einen störungsfreien Betrieb von bis zu 100.000 Zyklen zum Öffnen und Schließen von Türen mit einem Gewicht bis zu 100 kg gewährleisten',
    'S100_good_to_know': '* Optionen im Konfigurator verfügbar: Montagewinkel, Türschließdämpfer, Holzblende-Montagewinkel oder Aluminiumblende mit Kappen (bei Wandmontage möglich)\n* Türen nicht im Lieferumfang enthalten',
    'S120_description': '* Max. Türgewicht 120 kg\n* Schienenlänge ab 100 bis 200 cm\n* Decken- oder Wandmontage\n* Die Wagen sind mit hochfesten Kugellagern ausgestattet, die einen störungsfreien Betrieb von bis zu 100.000 Zyklen zum Öffnen und Schließen von Türen mit einem Gewicht bis zu 120 kg gewährleisten\n * Nylonräder sorgen für einen leisen Betrieb',
    'S120_good_to_know': '* Optionen im Konfigurator verfügbar: Montagewinkel, Türschließdämpfer, Holzblende-Montagewinkel oder Aluminiumblende mit Kappen (bei Wandmontage möglich)\n* Türen nicht im Lieferumfang enthalten',
    'S60_kit_description': '* Max. Türgewicht 60 kg\n* Die Wagen sind mit Nadellagern ausgestattet, die einen störungsfreien Betrieb von bis zu 100.000 Zyklen zum Öffnen und Schließen von Türen mit einem Gewicht bis zu 60 kg gewährleisten',
    'S100_kit_description': '* Max. Türgewicht 100 kg\n* Die Wagen sind mit Nadellagern ausgestattet, die einen störungsfreien Betrieb von bis zu 100.000 Zyklen zum Öffnen und Schließen von Türen mit einem Gewicht bis zu 100 kg gewährleisten',
    'S120_kit_description': '* Max. Türgewicht 120 kg\n* Die Wagen sind mit hochfesten Kugellagern ausgestattet, die einen störungsfreien Betrieb von bis zu 100.000 Zyklen zum Öffnen und Schließen von Türen mit einem Gewicht bis zu 120 kg gewährleisten\n * Nylonräder sorgen für einen leisen Betrieb',
    '2S60_description': '* Max. Türgewicht 60 kg\n* Schienenlänge ab 140 bis 200 cm\n* Decken- oder Wandmontage\n* Die Wagen sind mit Nadellagern ausgestattet, die einen störungsfreien Betrieb von bis zu 100.000 Zyklen zum Öffnen und Schließen von Türen mit einem Gewicht bis zu 60 kg gewährleisten',
    '2S100_description': '* Max. Türgewicht 100 kg\n* Schienenlänge ab 140 bis 200 cm\n* Decken- oder Wandmontage\n* Die Wagen sind mit Nadellagern ausgestattet, die einen störungsfreien Betrieb von bis zu 100.000 Zyklen zum Öffnen und Schließen von Türen mit einem Gewicht bis zu 100 kg gewährleisten',
    '2S120_description': '* Max. Türgewicht 120 kg\n* Schienenlänge ab 140 bis 200 cm\n* Decken- oder Wandmontage\n* Die Wagen sind mit hochfesten Kugellagern ausgestattet, die einen störungsfreien Betrieb von bis zu 100.000 Zyklen zum Öffnen und Schließen von Türen mit einem Gewicht bis zu 120 kg gewährleisten\n * Nylonräder sorgen für einen leisen Betrieb',
    '2S60_good_to_know': '* Optionen im Konfigurator verfügbar: Montagewinkel, Türschließdämpfer, Holzblende-Montagewinkel oder Aluminiumblende mit Kappen (bei Wandmontage möglich), Bausatz, das synchrone Öffnen und Schließen von 2 Türen durch Kabelzug ermöglicht\n* Türen nicht im Lieferumfang enthalten',
    '2S100_good_to_know': '* Optionen im Konfigurator verfügbar: Montagewinkel, Türschließdämpfer, Holzblende-Montagewinkel oder Aluminiumblende mit Kappen (bei Wandmontage möglich), Bausatz, das synchrone Öffnen und Schließen von 2 Türen durch Kabelzug ermöglicht\n* Türen nicht im Lieferumfang enthalten',
    '2S120_good_to_know': '* Optionen im Konfigurator verfügbar: Montagewinkel, Türschließdämpfer, Holzblende-Montagewinkel oder Aluminiumblende mit Kappen (bei Wandmontage möglich), Bausatz, das synchrone Öffnen und Schließen von 2 Türen durch Kabelzug ermöglicht\n* Türen nicht im Lieferumfang enthalten',
    'RO-S60_description': '* Max. Türgewicht 60 kg (mit 2 Laufwagen)',
    'RO-S100_description': '* Max. Türgewicht 100 kg (mit 2 Laufwagen)',
    'RO-S120_description': '* Max. Türgewicht 120 kg (mit 2 Laufwagen)',

    'LFT-B_description': '* Max. Türgewicht 100 kg\n * Wandmontage\n * Schienenlänge 200 cm\n * Finish: Schwarze matte',
    'LFT-B_good_to_know': '* Türstärke 35-45 mm\n * Türmaterial: Holz, Sperrholz, Span-, MDF- und Wabenplatte bestimmt\n * Anzahl der Türblätter: 1\n * Türen nicht im Lieferumfang enthalten',
    'G100_description': '* Max. Türgewicht 100 kg\n * Schienenlänge ab 100 bis 200 cm\n * Decken- oder Wandmontage\n * Es ist nicht erforderlich, Löcher in das Glas zu bohren\n * Die Wagen sind mit hochfesten Kugellagern ausgestattet, die einen störungsfreien Betrieb von bis zu 100.000 Zyklen zum Öffnen und Schließen von Türen mit einem Gewicht bis zu 100 kg gewährleisten\n * Nylonräder sorgen für einen leisen Betrieb',
    'G100_kit_description': '* Max. Türgewicht 100 kg\n * Es ist nicht erforderlich, Löcher in das Glas zu bohren\n * Die Wagen sind mit hochfesten Kugellagern ausgestattet, die einen störungsfreien Betrieb von bis zu 100.000 Zyklen zum Öffnen und Schließen von Türen mit einem Gewicht bis zu 100 kg gewährleisten\n * Nylonräder sorgen für einen leisen Betrieb',
    'G100_good_to_know': '* Optionen im Konfigurator verfügbar: Montagewinkel, Türschließdämpfer, Holzblende-Montagewinkel oder Aluminiumblende mit Kappen (bei Wandmontage möglich)\n * Türen nicht im Lieferumfang enthalten',
    'G80_description': '* Max. Türgewicht 80 kg\n * Schienenlänge ab 100 bis 200 cm\n * Decken- oder Wandmontage\n * Aufmerksamkeit. Es ist erforderlich, Löcher in das Glas zu bohren\n * Die Wagen sind mit Nadellagern ausgestattet, die einen störungsfreien Betrieb von bis zu 100.000 Zyklen zum Öffnen und Schließen von Türen mit einem Gewicht bis zu 80 kg gewährleisten',
    'G80_kit_description': '* Max. Türgewicht 80 kg\n * Aufmerksamkeit. Es ist erforderlich, Löcher in das Glas zu bohren\n * Die Wagen sind mit Nadellagern ausgestattet, die einen störungsfreien Betrieb von bis zu 100.000 Zyklen zum Öffnen und Schließen von Türen mit einem Gewicht bis zu 80 kg gewährleisten',
    'G80_good_to_know': '* Optionen im Konfigurator verfügbar: Montagewinkel, Holzblende-Montagewinkel\n * Türen nicht im Lieferumfang enthalten',
    'LFT-SCD_description': '* Der Dämpfer fängt die Tür auf, führt sie langsam in die geschlossene\n * Für Loft-Schiebesysteme\n * Set mit 2 Stück',

    'DS40_description': '* Der Dämpfer fängt die Tür auf, führt sie langsam in die geschlossene\n * Innerhalb der Schiene montiert\n * Kann an beiden Enden der Schiene montiert werden\n * Für Türen bis 45 kg\n * Für Schiebesysteme W40',
    'DS40_good_to_know': '* **Das Kit enthält:** \n * Dämpfer ×1\n * Dämpferhalterung ×2\n * Türhaken ×1\n * Befestigungsschrauben ×1 set',

    'SC-SG_description': '* Der Dämpfer fängt die Tür auf, führt sie langsam in die geschlossene\n * Kann an beiden Enden der Schiene montiert werden\n * Innerhalb der Schiene montiert\n * 3-stufige auf das Türgewicht einstellbar (≤40 kg, 41÷60 kg, 61÷80 kg)\n * Für Türen bis 80 kg\n * Kann auf eine vorhandene Tycho-Systeminstallation angewendet werden\n * Für Schiebesysteme S120, G100',
    'SCD-S_description': '* Der Dämpfer fängt die Tür auf, führt sie langsam in die geschlossene\n * Kann an beiden Enden der Schiene montiert werden\n * Innerhalb der Schiene montiert\n * 3-stufige auf das Türgewicht einstellbar (≤40 kg, 41÷60 kg, 61÷80 kg)\n * Für Türen bis 80 kg\n * Kann auf eine vorhandene Tycho-Systeminstallation angewendet werden\n * Für Schiebesysteme S60, S100',
    '2S_description': '* Bausatz, das synchrone Öffnen und Schließen von 2 Türen durch Kabelzug ermöglicht\n * Für Schiebesysteme S60, S100, S120\n * Max. Türgewicht 60 kg\n * Für Holztüren\n * Max. Öffnungsweite 4 m',

    'L30_description': '* Leichte und leise Schiebebewegungen\n * Schienenlänge ab 100 bis 200 cm\n * Max. Türgewicht 30 kg\n * Einfache und präzise Verstellung\n * Decken- oder Wandmontage',
    'L40_description': '* Leichte und leise Schiebebewegungen\n * Schienenlänge ab 100 bis 200 cm\n * Max. Türgewicht 40 kg\n * Einfache und präzise Verstellung\n * Decken- oder Wandmontage',

    'W40_description': '* Leichte und leise Schiebebewegungen\n * Schienenlänge ab 100 bis 200 cm\n * Max. Türgewicht 45 kg\n * Einfache und präzise Verstellung\n * Geringe Schienenhöhe\n * Deckenmontage\n * Türschließdämpfer verfügbar',
    'W40_kit_description': '* Leichte und leise Schiebebewegungen\n * Max. Türgewicht 45 kg\n * Einfache und präzise Verstellung\n',
    'W40_good_to_know': '* Türmaterial: Holz, Sperrholz, Span-, MDF- und Wabenplatte bestimmt\n * Türstärke 16–40 mm\n * System für 2 Türblätter: Schiene 100—180 cm\n * System für 3 Türblätter: Schiene 181—240 cm\n * Türen nicht im Lieferumfang enthalten',
    'W40_kit_good_to_know': '* Türmaterial: Holz, Sperrholz, Span-, MDF- und Wabenplatte bestimmt\n * Türstärke 16–40 mm\n * Türen nicht im Lieferumfang enthalten',
    
    'W60_description': '* Schiebetürsystem für Schranktüren / Nischen\n * Schienenlänge ab 100 bis 200 cm\n * Leichte und leise Schiebebewegungen\n * Einfache und präzise Verstellung\n * Geringe Schienenhöhe – Der Unterschied zwischen Türblatthöhe und lichte Höhe beträgt nur 8 mm\n * Max. Türgewicht 70 kg',
    'W60_kit_description': '* Schiebetürsystem für Schranktüren / Nischen\n * Leichte und leise Schiebebewegungen\n * Einfache und präzise Verstellung\n * Geringe Schienenhöhe – Der Unterschied zwischen Türblatthöhe und lichte Höhe beträgt nur 8 mm\n * Max. Türgewicht 70 kg',
    'W60_good_to_know': '* Türmaterial: Holz, Sperrholz, Span-, MDF- und Wabenplatte bestimmt\n * Türstärke ab 16 mm\n * Schienen können auseinander bewegt werden und die Tür jeder Dicke kann verwendet werden\n * System für 2 Türblätter: Schiene 100—180 cm\n * System für 3 Türblätter: Schiene 181—240 cm\n * Türen nicht im Lieferumfang enthalten',
    'W60_kit_good_to_know': '* Türmaterial: Holz, Sperrholz, Span-, MDF- und Wabenplatte bestimmt\n * Türstärke ab 16 mm\n * Türen nicht im Lieferumfang enthalten',

    'FSC_925_description': '* Für Schiebesysteme S60, S100, S120, G100\n * Blende wird auf die Schiene aufgeklipst, Wandmontage\n * Türstärke max. 25 mm\n * Höhe: 10 cm\n * Mit Staubschutzbürste',
    'FSC_925_good_to_know': '* HINWEIS: Länge im Konfigurator ist die Länge der Schiene (Blende wird 1 cm länger sein)',
    'FSC_945_description': '* Für Schiebesysteme S60, S100, S120\n* Blende wird auf die Schiene aufgeklipst, Wandmontage\n * Türstärke max. 45 mm\n * Höhe: 10 cm\n * Mit Staubschutzbürste',
    'FSC_945_good_to_know': '* HINWEIS: Länge im Konfigurator ist die Länge der Schiene (Blende wird 1 cm länger sein)',
    'FSC_980_description': '* Für Schiebesysteme S60, S100, S120, G100\n * Kann an einer Wand- oder Deckenschiene montiert werden',
    'FSC_980_good_to_know': '* Bei Montage an der an der Wand montierten Schiene mit Wandhalterungen, der Mindestabstand zwischen der Oberseite der Schiene und der Decke beträgt 15 mm\n * Bei Montage an der an der Decke montierten Schiene, Zwischen der Decke und der Oberseite der Schiene muss ein Abstand von 15 mm vorgesehen werden. Der erforderliche Abstand wird am besten mit verfügbaren Distanzhülsen erreicht',

    'FSC_937_description': '* Für Schiebesysteme S60, S100, S120, G100\n * Blende wird auf die Schiene aufgeklipst, Wandmontage\n * Türstärke max. 25 mm\n * Höhe: 10 cm\n * Mit Staubschutzbürste',
    'FSC_937_good_to_know': '* HINWEIS: Länge im Konfigurator ist die Länge der Schiene (Blende wird 1 cm länger sein)',
    'FSC_957_description': '* Für Schiebesysteme S60, S100, S120\n* Blende wird auf die Schiene aufgeklipst, Wandmontage\n * Türstärke max. 45 mm\n * Höhe: 10 cm\n * Mit Staubschutzbürste',
    'FSC_957_good_to_know': '* HINWEIS: Länge im Konfigurator ist die Länge der Schiene (Blende wird 1 cm länger sein)',
    'FSC_992_description': '* Für Schiebesysteme S60, S100, S120, G100\n * Kann an einer Wand- oder Deckenschiene montiert werden',
    'FSC_992_good_to_know': '* Bei Montage an der an der Wand montierten Schiene mit Wandhalterungen, der Mindestabstand zwischen der Oberseite der Schiene und der Decke beträgt 15 mm\n * Bei Montage an der an der Decke montierten Schiene, Zwischen der Decke und der Oberseite der Schiene muss ein Abstand von 15 mm vorgesehen werden. Der erforderliche Abstand wird am besten mit verfügbaren Distanzhülsen erreicht',

    'SPCR_description': '* Bietet einen Abstand zwischen der Schiene und der Decke, der für die Montage einer flachen Aluminiumblende erforderlich ist\n * Für Decke montierte Schiene\n * Für Schiebesysteme S60, S100, S120, G100',

    'F25_description': '* Max. Türgewicht 25 kg (beide Paneele)\n * Schienenlänge ab 80 bis 200 cm\n * Deckenmontage',
    'F40_description': '* Max. Türgewicht 40 kg (beide Paneele)\n * Schienenlänge ab 80 bis 200 cm\n * Deckenmontage',
    'F25_good_to_know': '* Türmaterial: Holz, Sperrholz, Span-, MDF- und Wabenplatte bestimmt\n * Türstärke 16–40 mm\n * Optionen im Konfigurator verfügbar: Holzblende-Montagewinkel\n * Türen nicht im Lieferumfang enthalten',
    'F40_good_to_know': '* Türmaterial: Holz, Sperrholz, Span-, MDF- und Wabenplatte bestimmt\n * Türstärke 16–40 mm\n * Optionen im Konfigurator verfügbar: Holzblende-Montagewinkel\n * Türen nicht im Lieferumfang enthalten',
    'F25_kit_description': '* Max. Türgewicht 25 kg (beide Paneele)\n * Kit für 2 zusätzliche Paneele',
    'F40_kit_description': '* Max. Türgewicht 40 kg (beide Paneele)\n * Kit für 2 zusätzliche Paneele',
    'RO-F25_description': '* Max. gewicht 25 kg',
    'RO-F40_description': '* Max. gewicht 40 kg',

    'WFB_UNI_description': '* Zur Montage einer Holzblende (Sperrholz, Span-, MDF- und Wabenplatte bestimmt oder ähnliches)\n * Für Systeme G80, F25, F40',
    'WFB_S_description': '* Zur Montage einer Holzblende (Sperrholz, Span-, MDF- und Wabenplatte bestimmt oder ähnliches)\n * Für Schiebesysteme S60, S100, S120, G100',
    'WFB_F_description': '* Zur Montage einer Holzblende (Sperrholz, Span-, MDF- und Wabenplatte bestimmt oder ähnliches)\n * Für Falttürsysteme F25, F40',
    'CB_25_UNI_description': '* Verbindet zwei an der Wand montierte Schienen\n * Für Schiebesystem G80\n * Türstärke max. 25 mm',
    'CB_25_S_description': '* Verbindet zwei an der Wand montierte Schienen\n * Für Schiebesysteme S60, S100, S120, G100\n * Türstärke max. 25 mm',
    'CB_45_S_description': '* Verbindet zwei an der Wand montierte Schienen\n * Für Schiebesysteme S60, S100, S120\n * Türstärke max. 45 mm',
    'WB_25_S_description': '* Zur Befestigung der Schiene an der Wand\n * Für Schiebesysteme S60, S100, S120, G100\n * Türstärke max. 25 mm',
    'WB_45_S_description': '* Zur Befestigung der Schiene an der Wand\n * Für Schiebesysteme S60, S100, S120\n * Türstärke max. 45 mm',
    'WB_25_UNI_description': '* Zur Befestigung der Schiene an der Wand\n * Für Schiebesystem G80\n * Türstärke max. 25 mm',

    'TRC_F_description': '* Do zastosowania z systemami do drzwi składanych F25, F40',
    'TRC_SG_description': '* Do zastosowania z systemami do drzwi przesuwnych S60, S100, S120, G80, G100',


    'LFT-HND-01_description': '* Für Holztüren\n * Höhe 20 cm\n * 1 Stück',
    'LFT-HND-02_description': '* Für Holztüren\n * Höhe 32 cm\n * 1 Stück',
    'LFT-HND-03_description': '* Für Holztüren\n * Höhe 28 cm\n * 1 Stück',
    'GHR-058_description': '* Für Glasschiebetüren\n * Für Glas mit einer Dicke von 8 bis 12 mm\n * Oberfläche: satinierte Stahl',
    'GHR-058-BM_description': '* Für Glasschiebetüren\n * Für Glas mit einer Dicke von 8 bis 12 mm\n * Oberfläche: mattschwarz',
    'TRC_F_description': '* Für Falttürsysteme F25, F40',
    'TRC_S_description': '* Für Schiebesysteme S60, S100, S120, G100',
    'TRC_UNI_description': '* Für Schiebesystem G80\n * Für Falttürsysteme F25, F40',
    'LG-01_description': '* Aus transparentem Kunststoff',
    'LG-02_description': '* Aus transparentem Kunststoff',

    'PURA_description': '* Entdecken Sie Modernität und Eleganz mit unserem Schiebetürsystem mit einer vollständig versteckten Schiene. Es wurde so entworfen, dass es perfekt in minimalistische Innenräume passt und einen reibungslosen und leisen Betrieb bietet.\n * Innovatives Design, das die Führung unsichtbar macht und so für ein sauberes und ästhetisches Erscheinungsbild sorgt.\n * Dank fortschrittlicher Technologie gleitet die Tür nahezu geräuschlos.\n * Hergestellt aus hochwertigen Materialien, garantiert Haltbarkeit und Zuverlässigkeit.',
    'PURA_good_to_know': '* Das System ist für Holzschiebetüren vorgesehen\n * Maximales Türgewicht 60 kg\n * Türbreite 80-120 cm\n * Türstärke 28-45 mm\n * Wandmontage\n * Das Set enthält keine Türen',
    'PURA_bullet_1': 'Maximales Türgewicht 80 kg',
    'PURA_bullet_2': 'Türblattbreite 80-120 cm',
    'PURA_bullet_3': 'Wandmontage',

    //Opisy Rysunkow
    'Rys.': 'Abb.',
    'ceiling_mounted': 'Deckenmontage',
    'wall_mounted': 'Wandmontage mit einzeln erhältlichen Winkeln',
    'wall_mounted_25': 'Wandmontage mit einzeln erhältlichen Winkeln 25',
    'wall_mounted_45': 'Wandmontage mit einzeln erhältlichen Winkeln 45',
    'wall_mounted_25_wooden_fascia': 'Wandmontage mit einzeln erhältlichen Winkeln 25 und Holzblende',
    'wall_mounted_45_wooden_fascia': 'Wandmontage mit einzeln erhältlichen Winkeln 45 und Holzblende',
    'wall_mounted_25_aluminium_fascia': 'Wandmontage mit einzeln erhältlichen Winkeln 25 und Aluminium-Blende 25',
    'wall_mounted_45_aluminium_fascia': 'Wandmontage mit einzeln erhältlichen Winkeln 45 und Aluminium-Blende 45',

    
    //Super krotki opis
    'Drzwi do 25 kg': 'Türen bis 25 kg',
    'Drzwi do 40 kg': 'Türen bis 40 kg',
    'Drzwi do 45 kg': 'Türen bis 45 kg',
    'Drzwi do 60 kg': 'Türen bis 60 kg',
    'Drzwi do 70 kg': 'Türen bis 70 kg',
    'Drzwi do 80 kg': 'Türen bis 80 kg',
    'Drzwi do 100 kg': 'Türen bis 100 kg',
    'Drzwi do 120 kg': 'Türen bis 120 kg',
    'Prowadnica 80–200 cm': 'Schiene 80–200 cm',
    'Prowadnica 100–300 cm': 'Schiene 100–200 cm',
    'Prowadnica 160–300 cm': 'Track 160–200 cm',
    'Prowadnica 200 cm': 'Schiene 200 cm',
    'Montaż do stropu lub ściany': 'Decken- oder Wandmontage',
    'Synchroniczne otwieranie 2 drzwi': 'Synchrone Öffnen von 2 Türen',
    'Do S60, S100, S120': 'Für S60, S100, S120',
    'Łagodnie domyka drzwi': 'Schließt die Tür reibungslos',
    'Niewidoczny montaż': 'Unsichtbare Montage',
    'Do drzwi przesuwnych szklanych': 'Für Glasschiebetüren',
    'Grubość szkła 8–12 mm': 'Glasdicke 8–12 mm',
    'Montaż do ściany': 'Wandmontage',
    'Montaż do stropu': 'Deckenmontage',
    'Niski tor jezdny': 'Geringe Schienenhöhe',

    'Mocowana na szynę montowaną naściennie': 'Maskiert die Schiene an der Wand montiert',
    'Do systemów G80, F25, F40': 'Für systeme G80, F25, F40',
    'Do systemów F25, F40': 'Für systeme F25, F40',
    'Do systemów S60, S100, S120, G80, G100': 'Für systeme S60, S100, S120, G80, G100',
    'Do systemów G80, G100': 'Für systeme G80, G100',
    'Do systemów S60, S100, S120': 'Für systeme S60, S100, S120',
    'Do systemów S60, S100, S120, G100': 'Für systeme S60, S100, S120, G100',
    'Grubość drzwi do 25 mm': 'Türstärke max. 25 mm',
    'Grubość drzwi do 45 mm': 'Türstärke max. 45 mm',
    'Na dodatkowe skrzydło drzwi': 'Für zusätzliche Tür',
    'Komplet 2 szt.': 'Set mit 2 Stück',
    'Do drzwi drewnianych': 'Für Holztüren',
    'Wysokość 19.5 cm': 'Höhe 19.5 cm',
    'Wysokość 28 cm': 'Höhe 28 cm',
    'Wysokość 32 cm': 'Höhe 32 cm',
    '1 szt.': '1 Stück',
    'Obciążenie do 60 kg (na 2 wózkach)': 'Max. 60 kg (mit 2 Laufwagen)',
    'Obciążenie do 100 kg (na 2 wózkach)': 'Max. 100 kg (mit 2 Laufwagen)',
    'Obciążenie do 120 kg (na 2 wózkach)': 'Max. 120 kg (mit 2 Laufwagen)',
    'Obciążenie do 25 kg': 'Max. 25 kg',
    'Obciążenie do 40 kg': 'Max. 40 kg',
    'Z przezroczystego tworzywa': 'Aus transparentem Kunststoff',
    'Do prowadnicy mocowanej do sufitu': 'Für Decke montierte Schiene',
    'Łączy dwie prowadnice mocowane na ścianie': 'Verbindet zwei an der Wand montierte Schienen',
    'Umożliwia montaż prowadnicy na ścianie': 'Zur Befestigung der Schiene an der Wand',
    'Umożliwia montaż maskownicy drewnianej': 'Zur Montage einer Holzblende',

    //Manuals
    'PURA_manual': 'Pura System Montageanleitung',
    'L30_manual': 'L30 System Montageanleitung',
    'L40_manual': 'L40 System Montageanleitung',
    'W60_manual': 'W60 System Montageanleitung',
    'W40_manual_2': 'W40 System Montageanleitung (zweitüriger Schrank)',
    'W40_manual_3': 'W40 System Montageanleitung (dreitüriger Schrank)',
    'DS40_manual': 'Türschließdämpfer Montageanleitung',

    'F25_manual': 'F25 System Montageanleitung',
    'F40_manual': 'F40 System Montageanleitung',
    'S60_manual': 'S60 System Montageanleitung',
    'S100_manual': 'S100 System Montageanleitung',
    'S120_manual': 'S120 System Montageanleitung',
    'G80_manual': 'G80 System Montageanleitung',
    'G100_manual': 'G100 System Montageanleitung',
    'WB_45_S_manual': 'Montagewinkel 45 S Montageanleitung',
    'WB_25_S_manual': 'Montagewinkel 25 S Montageanleitung',
    'WB_40_UNI_manual': 'Montagewinkel 40 UNI Montageanleitung',
    'WB_25_UNI_manual': 'Montagewinkel 25 UNI Montageanleitung',
    'WFB_UNI_manual': 'Holzblende-Montagewinkel Montageanleitung ',
    'WFB_S_manual': 'Holzblende-Montagewinkel Montageanleitung ',
    'WFB_F_manual': 'Holzblende-Montagewinkel Montageanleitung ',
    'SCD-S_manual': 'Türschließdämpfer Montageanleitung',
    'SC-SG_manual': 'Türschließdämpfer Montageanleitung',
    '2S_manual': '2S Synchronisationsbausatz Montageanleitung',
    'FSC925_manual': 'Eloxierte Aluminiumblende mit Kappen 25 Montageanleitung',
    'FSC945_manual': 'Eloxierte Aluminiumblende mit Kappen 45 Montageanleitung',
    'FSC980_manual': 'Flache Aluminium-Blende Montageanleitung',

    //Checkout
    'Creating system': 'Ihr personalisiertes Produkt wird in einer Datenbank erstellt.',
    'Redirecting': 'Sie werden bald zur Kasse weitergeleitet.',
    'Please be patient': 'Es wird kurz dauern.',


}