module.exports = {
    'Dodaj do koszyka': 'In winkelwagen',
    'Koszyk': 'Winkelwagen',
    'Twój koszyk jest pusty.': 'Uw winkelwagen is leeg.',
    'Wartość koszyka': 'Totaal',
    'Przejdź do kasy': 'Ga naar de kassa',
    'Znaleziono': 'Resultaten',
    'Szukaj produktów': 'Zoeken naar producten',
    'Ceny: od': 'Sorteren op:',
    'od ': 'vanaf ',
    'najwyższej': 'Hoogste prijs',
    'najniższej': 'Laagste prijs',
    'loading': 'loading',

    'Strona Główna': 'Home',
    'Produkty': 'Producten',
    'Seria F': 'F-serie',
    'Seria G': 'G-serie',
    'Seria L': 'L-serie',
    'Seria Loft': 'Loft-serie',
    'Seria S': 'S-serie',
    'Seria W': 'W-serie',

    'Zawartość zestawu': 'Inhoud kit',
    'Główne cechy': 'Belangrijkste kenmerken',
    'Gwarancja, zwroty, dostawa': 'Garantie, retourneren, levering',
    'Przydatne informacje': 'Bruikbare informatie',
    'Do pobrania': 'Downloads',
    'Może Ci się spodobać': 'Misschien is dit ook interessant voor jou',

    'warranty_info': 'Vijfjarige garantie op alle onderdelen van de kit.',
    'return_info': 'Gratis retourneren binnen 30 dagen.',
    'shipping_info_0': 'Verzending ',
    'shipping_info_1': 'De verzendkosten bedragen ​4.70 €',
    'shipping_info_2': 'Het verzenden duurt 3-9 dagen',
    'shipping_info_2B': 'Het verzenden duurt 2-7 dagen',
    'shipping_info_3': '',

    //Menu
    'Systemy do drzwi przejściowych': 'Scheidingsdeursystemen',
    'Systemy do drzwi w szafach': 'Kastdeursystemen',
    'Seria S – przesuwne': 'S-serie Schuifdeursystemen',
    'Seria G – przesuwne do szkła': 'G-serie Schuifdeursysteem voor glazen deuren',
    'Seria Loft – przesuwne': 'Loft-serie Schuifdeursystemen',
    'Seria F – składane': 'F-serie Bifold deursystemen',
    'Seria W – przesuwne': 'W-serie Schuifsystemen voor garderobekastdeuren',
    'Akcesoria': "Accessoires",
    'Akcesoria G100': "G100-accessoires",
    'Akcesoria Loft': "Loft-accessoires",
    'Systemy Loft': "Loft Systems",

    // Konfigurator 
    'Wariant': 'Variant',
    'Produkt': 'Product',
    'Prowadnica': 'Rail',
    'Długość': 'Lengte',
    'Mocowanie': 'Beslag',
    'Do stropu': 'Plafond',
    'Do ściany': 'Muur',
    'Grubość drzwi': 'Deurdikte',
    'Maskownica aluminiowa': 'Aluminium latei',
    'Maskownica aluminiowa płaska': 'Plat aluminium latei',
    'Maskownica płaska': 'Plat latei',
    'Maskownica alu.': 'Aluminium latei',
    'Uchwyt maskownicy drewnianej': 'Houten lateihaak',
    'Uchwyt maskow. drewn.': 'Houten lateihaak',
    'Tak': 'Ja',
    'Nie': 'Nee',
    'Srebrna': 'Zilver',
    'Czarna': 'Zwart',
    'Miękkie domykanie': 'Zachte sluitdemper',
    '1 strona': '1 einde',
    '2 strony': '2 uiteinden',
    "Otwieranie synchroniczne": 'Synchronisatiekit',
    "Tuleja dystansowa": 'Afstandshuls',

    // Zawartosc zestawu 
    'Wózek z materiału o wysokiej wytrzymałości — poliacetalu. Osie kół ze stali łożyskowej w łożyskach kulkowych': 'Roller gemaakt van hoogresistent materiaal - polyoxymethyleen',
    'Wózek z materiału o wysokiej wytrzymałości — poliacetalu. Osie kół ze stali łożyskowej w łożyskach igiełkowych': 'Roller gemaakt van hoogresistent materiaal - polyoxymethyleen',
    'Prowadnica aluminiowa z otworami pod wkręty Ø 4 mm': 'Aluminium rail met montagegaten Kan op maat gesneden worden',
    'Stoper blokujący drzwi w krańcowych położeniach': 'Eindstop',
    'Stoper odbijający': 'Eindbuffer',
    'Mocowanie szkła': 'Glasbeslag',
    'Wkładki elastomerowe do zacisków, dla różnej grubość szkła': 'Elastomeer inzetstukken voor verschillende glasdiktes',
    'Samoprzylepne uchwyty do drzwi z bezbarwnego tworzywa': 'Zelfklevende deurklinken gemaakt van transparant plastic',
    'Prowadnik dolny wykonany z bezbarwnego tworzywa': 'Doorzichtige ondergeleider',
    'Klucz imbusowy': 'Inbussleutel',
    'Komplet kluczy imbusowych': 'Inbussleutelset',
    'Obsada czopu górnego': 'Bovenste holle schacht',
    'Czop górny': 'Bovenste pinn',
    'Czop dolny': 'Onderste Pin',
    'Obsada czopu dolnego': 'Onderste holle schacht',
    'Zawias': 'Scharnier',
    'Wkręty montażowe': 'Schroeven',
    'Wkręty mocujące': 'Schroeven',
    'Domykacz': 'Zachte sluitdemper',

    'Zaczep do drzwi drewnianych': 'Hook for wooden door',
    'Zaczep do drzwi szklanych': 'Hook for glass door',
    'Zaczep uniwersalny': 'Multi-purpose hook',
    'Podkładka': 'Support',

    'Domykacz wyhamowuje drzwi w skrajnym położeniu i łagodnie je domyka. Do drzwi o wadze do 80 kg': 'Zachte sluitdemper. De demper sluit de deur soepel en langzaam. Voor deuren tot 80 kg',
    'Uchwyt maskownicy': 'Houten lateihaak',
    'Klamra ścienna 45': 'Muurbevestigingssteun 45',
    'Klamra ścienna 25': 'Muurbevestigingssteun 25',
    'Maskownica aluminiowa z zaślepkami bocznymi i szczotką przeciwkurzową': 'Geanodiseerde aluminium latei met eindkappen',
    'Linka stalowa – 8 m': 'Kabel – 8 m',
    'Stoper z rolką': 'Gemonteerde trolleys',
    'Wspornik montażowy ze sprężyną': 'Fixeerplaat met veerbuffer',
    'Łącznik': 'Rijdend hoekplaatje',
    'Prowadnica stalowa': 'Stalen rail',
    'Wózek': 'Roller',
    'Stoper': 'Stopper',
    'Mocowanie prowadnicy': 'Muurbeslag',
    'Ogranicznik górny': 'Bovenstopper',
    'Prowadnik dolny': 'Ondergeleider',
    'Komplet zaślepek bocznych': 'Eindkappen',
    'Szczotka przeciwkurzowa': 'Stofborstel',
    
    //Home
    'Zobacz więcej': 'Bekijk meer',
    'System przesuwny': 'Schuifdeursysteem',
    'Systemy przesuwne': 'Schuifdeursystemen',
    'Systemy przesuwne do szkła': 'Oplossingen voor glazen deuren',
    'Systemy do lekkich drzwi przesuwnych': 'Systemen voor lichte schuifdeuren',
    'Systemy składane': 'Vouwdeursystemen',
    'Systemy do drzwi przesuwnych \n w szafach i zabudowach wnęk': 'Schuifsystemen voor garderobekastdeuren',
    'hero-copy-pura': 'Ontdek moderniteit en elegantie met ons schuifdeursysteem met volledig verborgen rail. Ontworpen om perfect in minimalistische interieurs te passen, biedt het een soepele en stille werking. Een innovatief ontwerp dat de geleider onzichtbaar maakt, wat zorgt voor een strak en esthetisch uiterlijk. Dankzij geavanceerde technologie glijden de deuren vrijwel geruisloos. Gemaakt van hoogwaardige materialen, garandeert het een lange levensduur en betrouwbaarheid.',
    'hero-copy-s-series': 'De S-serie bestaat uit drie systemen voor schuifdeuren: tot 60kg, 100kg en 120kg. Elk systeem kan worden gemonteerd aan het plafond of aan de muur met het gebruik van optionele bevestigingsbeugels voor aan de muur en worden uitgerust met houten lateihaken, zelfsluitende dempers en, in het geval van systemen met twee deurvleugels, een set waarmee het synchroon openen mogelijk wordt. Alle systemen kunnen worden uitgerust met een aluminium latei, geheel geschikt voor moderne interieurs.',
    'hero-copy-g-series': 'Wij leveren twee systemen voor glazen schuifdeuren. Het G80-systeem (belasting deurvleugel tot 80kg) kan naar keuze worden gemonteerd aan het plafond of aan de muur. Voor het G100-systeem (belasting deurvleugel tot 100kg) hoeven geen gaten in het glas worden geboord. Het kan worden uitgerust met een aluminium latei en zelfsluitende dempers. Het kan zowel aan het plafond als aan de muren worden gemonteerd.',
    'hero-copy-loft-series': 'Een serie van systemen die doen denken aan klassieke schuifdeursystemen. Ze passen in interieurs met een onderscheidend karakter die doet denken aan een loft of een rustieke sfeer geeft. De systemen worden geleverd met verschillende soorten trolleys en in twee kleuren, zwart en wit. Ze kunnen worden uitgerust met een set zelfsluiters en deurklinken in dezelfde stijl.',
    

    //Opisy
    'S60_description': '* Max. gewicht deur 60 kg\n* Lengte van de rail 100-200 cm\n*Montage aan het plafond of aan de muur\n* Rollen zijn uitgerust met naaldlagers die zorgen voor een probleemloze werking van maximaal 100.000 cycli voor het openen en sluiten van deuren met een gewicht tot 60 kg',
    'S60_good_to_know': '* Te gebruiken opties in de configurator: muurbevestigingssteunen, zachte sluitdempers, houten lateihaken, aluminium latei met eindkappen (mogelijk bij bevestiging aan de muur)\n* Deuren niet inbegrepen in de levering',
    'S100_description': '* Max. gewicht deur 100 kg\n* Lengte van de rail 100-200 cm\n*Montage aan het plafond of aan de muur\n* Rollen zijn uitgerust met zeer sterke naaldlagers die zorgen voor een probleemloze werking van maximaal 100.000 cycli voor het openen en sluiten van deuren met een gewicht tot 100 kg',
    'S100_good_to_know': '* Te gebruiken opties in de configurator: muurbevestigingssteunen, zachte sluitdempers, houten lateihaken, aluminium latei met eindkappen (mogelijk bij bevestiging aan de muur)\n* Deuren niet inbegrepen in de levering',
    'S120_description': '* Max. gewicht deur 120 kg\n* Lengte van de rail 100-200 cm\n*Montage aan het plafond of aan de muur\n* Rollen zijn uitgerust met kogellagers met de hoogste sterkte die zorgen voor een probleemloze werking van maximaal 100.000 cycli voor het openen en sluiten van deuren met een gewicht tot 120 kg\n * Nylon wielen zorgen voor een stille werking',
    'S120_good_to_know': '* Te gebruiken opties in de configurator: muurbevestigingssteunen, zachte sluitdempers, houten lateihaken, aluminium latei met eindkappen (mogelijk bij bevestiging aan de muur)\n* Deuren niet inbegrepen in de levering',
    'S60_kit_description': '* Max. gewicht deur 60 kg\n* Rollen zijn uitgerust met naaldlagers die zorgen voor een probleemloze werking van maximaal 100.000 cycli voor het openen en sluiten van deuren met een gewicht tot 60 kg',
    'S100_kit_description': '* Max. gewicht deur 100 kg\n* Rollen zijn uitgerust met zeer sterke naaldlagers die zorgen voor een probleemloze werking van maximaal 100.000 cycli voor het openen en sluiten van deuren met een gewicht tot 100 kg',
    'S120_kit_description': '* Max. gewicht deur 120 kg\n* Rollen zijn uitgerust met kogellagers met de hoogste sterkte die zorgen voor een probleemloze werking van maximaal 100.000 cycli voor het openen en sluiten van deuren met een gewicht tot 120 kg\n * Nylon wielen zorgen voor een stille werking',
    '2S60_description': '* Max. gewicht deur 60 kg\n* Lengte van de rail 140-200 cm\n*Montage aan het plafond of aan de muur\n* Rollen zijn uitgerust met naaldlagers die zorgen voor een probleemloze werking van maximaal 100.000 cycli voor het openen en sluiten van deuren met een gewicht tot 60 kg',
    '2S100_description': '* Max. gewicht deur 100 kg\n* Lengte van de rail 140-200 cm\n*Montage aan het plafond of aan de muur\n* Rollen zijn uitgerust met zeer sterke naaldlagers die zorgen voor een probleemloze werking van maximaal 100.000 cycli voor het openen en sluiten van deuren met een gewicht tot 100 kg',
    '2S120_description': '* Max. gewicht deur 120 kg\n* Lengte van de rail 140-200 cm\n*Montage aan het plafond of aan de muur\n* Rollen zijn uitgerust met kogellagers met de hoogste sterkte die zorgen voor een probleemloze werking van maximaal 100.000 cycli voor het openen en sluiten van deuren met een gewicht tot 120 kg\n * Nylon wielen zorgen voor een stille werking',
    '2S60_good_to_know': '* Te gebruiken opties in de configurator: muurbevestigingssteunen, zachte sluitdempers, houten lateihaken, aluminium latei met eindkappen (mogelijk bij bevestiging aan de muur), set voor het synchroon openen en sluiten van twee deurvleugels in tegengestelde richting\n* Deuren niet inbegrepen in de levering',
    '2S100_good_to_know': '* Te gebruiken opties in de configurator: muurbevestigingssteunen, zachte sluitdempers, houten lateihaken, aluminium latei met eindkappen (mogelijk bij bevestiging aan de muur), set voor het synchroon openen en sluiten van twee deurvleugels in tegengestelde richting\n* Deuren niet inbegrepen in de levering',
    '2S120_good_to_know': '* Te gebruiken opties in de configurator: muurbevestigingssteunen, zachte sluitdempers, houten lateihaken, aluminium latei met eindkappen (mogelijk bij bevestiging aan de muur), set voor het synchroon openen en sluiten van twee deurvleugels in tegengestelde richting\n* Deuren niet inbegrepen in de levering',
    'RO-S60_description': '* Max. gewicht deur 60 kg (met twee rollers)',
    'RO-S100_description': '* Max. gewicht deur 100 kg (met twee rollers)',
    'RO-S120_description': '* Max. gewicht deur 120 kg (met twee rollers)',
    'LFT-B_description': '* Max. gewicht deur 100 kg\n * Montage aan de muur\n * Lengte van de rail 200 cm\n * Zwarte matte afwerking',
    'LFT-B_good_to_know': '* Deurdikte 35-45 mm\n * Deur materiaal: Hout, multiplex, spaanplaat, MDF en honingraatpanelen\n * Aantal deurvleugels: 1\n * Deuren niet inbegrepen in de levering',
    'G100_description': '* Max. gewicht deur 100 kg\n * Lengte van de rail 100-200 cm\n *Montage aan het plafond of aan de muur\n * Rollen zijn uitgerust met kogellagers met de hoogste sterkte die zorgen voor een probleemloze werking van maximaal 100.000 cycli voor het openen en sluiten van deuren met een gewicht tot 100 kg\n * Nylon wielen zorgen voor een stille werking',
    'G100_kit_description': '* Max. gewicht deur 100 kg\n * Rollen zijn uitgerust met kogellagers met de hoogste sterkte die zorgen voor een probleemloze werking van maximaal 100.000 cycli voor het openen en sluiten van deuren met een gewicht tot 100 kg\n * Nylon wielen zorgen voor een stille werking',
    'G100_good_to_know': '* Te gebruiken opties in de configurator: muurbevestigingssteunen, zachte sluitdempers, houten lateihaken, aluminium latei met eindkappen (mogelijk bij bevestiging aan de muur)\n * Deuren niet inbegrepen in de levering',
    'G80_description': '* Max. gewicht deur 80 kg\n * Lengte van de rail 100-200 cm\n *Montage aan het plafond of aan de muur\n * Rollen zijn uitgerust met zeer sterke naaldlagers die zorgen voor een probleemloze werking van maximaal 100.000 cycli voor het openen en sluiten van deuren met een gewicht tot 80 kg',
    'G80_kit_description': '* Max. gewicht deur 80 kg\n * Rollen zijn uitgerust met zeer sterke naaldlagers die zorgen voor een probleemloze werking van maximaal 100.000 cycli voor het openen en sluiten van deuren met een gewicht tot 80 kg',
    'G80_good_to_know': '* Te gebruiken opties in de configurator: muurbevestigingssteunen, houten lateihaken\n * Deuren niet inbegrepen in de levering',

    'LFT-SCD_description': '* De dempers sluiten de deur soepel en langzaam\n * Voor de Loft-schuifsystemen\n * Set van 2 st.',
    'SC-SG_description': '* De demper sluit de deur soepel en langzaam\n * Kan aan beide zijden van de rail worden gemonteerd\n *Gemonteerd in de rail \n * Afstelling van de sluitkracht in drie delen (≤40 kg, 41-60 kg, 61-80 kg)\n * Voor deuren tot 80 kg\n * Kan worden toegepast op een bestaande Tycho-systeeminstallatie\n * Voor schuifsystemen S120, G100',
    'SCD-S_description': '* De demper sluit de deur soepel en langzaam\n * Kan aan beide zijden van de rail worden gemonteerd\n *Gemonteerd in de rail \n * Afstelling van de sluitkracht in drie delen (≤40 kg, 41-60 kg, 61-80 kg)\n * Voor deuren tot 80 kg\n * Kan worden toegepast op een bestaande Tycho-systeeminstallatie\n * Voor schuifsystemen S60, S100',
    '2S_description': '* Set voor het synchroon openen en sluiten van twee deurvleugels in tegengestelde richting\n * Voor schuifdeursystemen S60, S100, S120\n * Max. gewicht deur 60 kg\n * Voor houten deuren\n * Max. openingsbreedte 4 m',

    'W40_description': '* Soepele en geluidloze kogellagerrollers\n * Raillengte 100-200 cm\n * Max. gewicht deur 45 kg\n * Eenvoudige montage zonder het gebruik van speciaal gereedschap\n * Lage spoorhoogte\n * Montage aan het plafond\n * Zachte sluitdempers beschikbaar',
    'W40_good_to_know': '* Deur materiaal: Hout, multiplex, spaanplaat, MDF en honingraatpanelen\n * Deurdikte 16–40 mm\n * Systeem voor 2 deurvleugels: Rail 100—180 cm\n * Systeem voor 3 deurvleugels: Rail 181—200 cm\n * Deuren niet inbegrepen in de levering',
    'W60_description': '* Schuifdeursysteem voor kledingkasten\n * Raillengte 100-200 cm\n * Soepele en geluidloze kogellagerrollers\n * Eenvoudige montage zonder het gebruik van speciaal gereedschap\n * Zeer railhoogte – verschil tussen deurbladhoogte en dagmaathoogte is slechts 8 mm\n * Max. gewicht deur 70 kg',
    'W60_good_to_know': '* Deur materiaal: Hout, multiplex, spaanplaat, MDF en honingraatpanelen\n * Deurdikte from 16 mm\n * Rails kunnen uit elkaar worden bewogen en de deur van elke dikte kan worden gebruikt\n * Systeem voor 2 deurvleugels: Rail 100—180 cm\n * Systeem voor 3 deurvleugels: Rail 181—240 cm\n * Deuren niet inbegrepen in de levering',

    'FSC_925_description': '* Voor schuifdeursystemen S60, S100, S120, G100\n * Montage aan de muur bevestigde rail\n * Max. Deurdikte 25 mm\n * Hoogte: 10 cm\n * Met stofborstel',
    'FSC_925_good_to_know': '* OPMERKING: Lengte in de configurator geeft de lengte van de rail aan (de latei zal 1 cm langer zijn)',
    'FSC_945_description': '* Voor schuifdeursystemen S60, S100, S120\n* Montage aan de muur bevestigde rail\n * Max. Deurdikte 45 mm\n * Hoogte: 10 cm\n * Met stofborstel',
    'FSC_945_good_to_know': '* OPMERKING: Lengte in de configurator geeft de lengte van de rail aan (de latei zal 1 cm langer zijn)',
    'FSC_980_description': '* Voor schuifdeursystemen S60, S100, S120, G100\n * Kan worden gemonteerd op een muur- of plafondmontagerail',
    'FSC_980_good_to_know': '* When assembled to the wall mounted rail with wall brackets, the minimum distance between the rail\'s upper surface and the ceiling is 15 mm\n * When assembled to the ceiling mounted rail, distance of 15 mm must be provided between the ceiling and the upper surface of the rail. The required distance is best obtained by using available spacers',

    'FSC_937_description': '* Voor schuifdeursystemen S60, S100, S120, G100\n * Montage aan de muur bevestigde rail\n * Max. Deurdikte 25 mm\n * Hoogte: 10 cm\n * Met stofborstel',
    'FSC_937_good_to_know': '* OPMERKING: Lengte in de configurator geeft de lengte van de rail aan (de latei zal 1 cm langer zijn)',
    'FSC_957_description': '* Voor schuifdeursystemen S60, S100, S120\n* Montage aan de muur bevestigde rail\n * Max. Deurdikte 45 mm\n * Hoogte: 10 cm\n * Met stofborstel',
    'FSC_957_good_to_know': '* OPMERKING: Lengte in de configurator geeft de lengte van de rail aan (de latei zal 1 cm langer zijn)',
    'FSC_992_description': '* Voor schuifdeursystemen S60, S100, S120, G100\n * Kan worden gemonteerd op een muur- of plafondmontagerail',
    'FSC_992_good_to_know': '* When assembled to the wall mounted rail with wall brackets, the minimum distance between the rail\'s upper surface and the ceiling is 15 mm\n * When assembled to the ceiling mounted rail, distance of 15 mm must be provided between the ceiling and the upper surface of the rail. The required distance is best obtained by using available spacers',

    'SPCR_description': '* Provides a distance between the rail and the ceiling, necessary for the assembly of a flat aluminum fascia\n * For ceiling mounted rail\n * Voor schuifdeursystemen S60, S100, S120, G100',

    'F25_description': '* Max. gewicht deur 25 kg (both panels)\n * Raillengte 80-200 cm\n * Montage aan het plafond',
    'F40_description': '* Max. gewicht deur 40 kg (both panels)\n * Raillengte 80-200 cm\n * Montage aan het plafond',
    'F25_good_to_know': '* Deur materiaal: Hout, multiplex, spaanplaat, MDF en honingraatpanelen\n * Deurdikte 16–40 mm\n * Te gebruiken opties in de configurator: houten lateihaken\n * Deuren niet inbegrepen in de levering',
    'F40_good_to_know': '* Deur materiaal: Hout, multiplex, spaanplaat, MDF en honingraatpanelen\n * Deurdikte 16–40 mm\n * Te gebruiken opties in de configurator: houten lateihaken\n * Deuren niet inbegrepen in de levering',
    'F25_kit_description': '* Max. gewicht deur 25 kg (both panels)\n * Kit voor 2 extra panelen',
    'F40_kit_description': '* Max. gewicht deur 40 kg (both panels)\n * Kit voor 2 extra panelen',
    'RO-F25_description': '* Max. gewicht 25 kg',
    'RO-F40_description': '* Max. gewicht 40 kg',
    'WFB-SG_description': '*Maakt installatie van een boeiboord van hout mogelijk (multiplex, spaanplaat, MDF, honingraatpaneel of iets dergelijks)\n * Voor schuifdeursystemen S60, S100, S120, G80, G100',
    'WFB-F_description': '*Maakt installatie van een boeiboord van hout mogelijk (multiplex, spaanplaat, MDF, honingraatpaneel of iets dergelijks)\n * Voor vouwdeursystemen F25, F40',
    'CB-25_description': '* Verbindt twee aan de muur gemonteerde rails\n * Voor schuifdeursystemen S60, S100, S120, G80, G100\n * Max. Deurdikte 25 mm',
    'CB-45_description': '* Verbindt twee aan de muur gemonteerde rails\n * Voor schuifdeursystemen S60, S100, S120\n * Max. Deurdikte 45 mm',
    'WB-25_description': '* Muursteun voor het monteren van de rai aan de muur\n * Voor schuifdeursystemen S60, S100, S120, G80, G100\n * Max. Deurdikte 25 mm',
    'WB-45_description': '* Muursteun voor het monteren van de rai aan de muur\n * Voor schuifdeursystemen S60, S100, S120\n * Max. Deurdikte 45 mm',

    'LFT-HND-01_description': '* Voor houten deuren\n * Hoogte 20 cm\n * 1 stuk',
    'LFT-HND-02_description': '* Voor houten deuren\n * Hoogte 32 cm\n * 1 stuk',
    'LFT-HND-03_description': '* Voor houten deuren\n * Hoogte 28 cm\n * 1 stuk',
    'GHR-058_description': '* Voor glazen schijfdeuren\n * Glasdikte 8-12 mm\n * Afwerking: gesatineerd staal',
    'GHR-058-BM_description': '* Voor glazen schijfdeuren\n * Glasdikte 8-12 mm\n * Afwerking: mat zwart',
    'TRC_F_description': '* Voor vouwdeursystemen F25, F40',
    'TRC_SG_description': '* Voor schuifdeursystemen S60, S100, S120, G80, G100',
    'LG-01_description': 'Gemaakt van transparant plastic',
    'LG-02_description': 'Gemaakt van transparant plastic',

    'PURA_description': '* Ontdek moderniteit en elegantie met ons schuifdeursysteem met volledig verborgen rail. Ontworpen om perfect in minimalistische interieurs te passen, biedt het een soepele en stille werking.\n * Innovatief ontwerp dat de geleider onzichtbaar maakt, wat zorgt voor een strak en esthetisch uiterlijk.\n * Dankzij geavanceerde technologie schuift de deur vrijwel geruisloos.\n * Gemaakt van hoogwaardige materialen, garandeert duurzaamheid en betrouwbaarheid.',
    'PURA_good_to_know': '* Het systeem is bedoeld voor houten schuifdeuren\n * Maximaal deurgewicht 60 kg\n * Deurbreedte 80-120 cm\n * Deurdikte 28-45 mm\n * Wandmontage\n * De set is exclusief deuren',
    'PURA_bullet_1': 'Maximaal deurgewicht 80 kg',
    'PURA_bullet_2': 'Deurbladbreedte 80-120 cm',
    'PURA_bullet_3': 'Wandmontage',

    //Super krotki opis
    'Drzwi do 25 kg': 'Deuren tot 25 kg',
    'Drzwi do 40 kg': 'Deuren tot 40 kg',
    'Drzwi do 45 kg': 'Deuren tot 45 kg',
    'Drzwi do 60 kg': 'Deuren tot 60 kg',
    'Drzwi do 70 kg': 'Deuren tot 70 kg',
    'Drzwi do 80 kg': 'Deuren tot 80 kg',
    'Drzwi do 100 kg': 'Deuren tot 100 kg',
    'Drzwi do 120 kg': 'Deuren tot 120 kg',
    'Prowadnica 80–200 cm': 'Rail 80–200 cm',
    'Prowadnica 100–300 cm': 'Rail 100–200 cm',
    'Prowadnica 160–300 cm': 'Rail 160–200 cm',
    'Prowadnica 200 cm': 'Rail 200 cm',
    'Montaż do stropu lub ściany': 'Plafond- of wandmontage',
    'Synchroniczne otwieranie 2 drzwi': 'Synchroon openen van 2 deuren',
    'Do S60, S100, S120': 'Voor S60, S100, S120',
    'Łagodnie domyka drzwi': 'Sluit de deur soepel',
    'Niewidoczny montaż': 'Onzichtbare montage',
    'Do drzwi przesuwnych szklanych': 'Voor glazen schijfdeuren',
    'Grubość szkła 8–12 mm': 'Glasdikte 8-12 mm',
    'Montaż do ściany': 'Wandmontage',
    'Montaż do stropu': 'Montage aan het plafond',
    'Niski tor jezdny': 'Lage spoorhoogte',

    'Mocowana na szynę montowaną naściennie': 'Montage aan de muur bevestigde rail',
    'Do systemów F25, F40': 'Voor systemen F25, F40',
    'Do systemów S60, S100, S120, G80, G100': 'Voor systemen S60, S100, S120, G80, G100',
    'Do systemów G80, G100': 'Voor systemen G80, G100',
    'Do systemów S60, S100, S120': 'Voor systemen S60, S100, S120',
    'Do systemów S60, S100, S120, G100': 'Voor systemen S60, S100, S120, G100',
    'Grubość drzwi do 25 mm': 'Max. Deurdikte 25 mm',
    'Grubość drzwi do 45 mm': 'Max. Deurdikte 45 mm',
    'Na dodatkowe skrzydło drzwi': 'Voor extra deurblad',
    'Komplet 2 szt.': 'Set van 2 st.',
    'Do drzwi drewnianych': 'Voor houten deuren',
    'Wysokość 19.5 cm': 'Hoogte 19.5 cm',
    'Wysokość 28 cm': 'Hoogte 28 cm',
    'Wysokość 32 cm': 'Hoogte 32 cm',
    '1 szt.': '1 stuk',
    'Obciążenie do 60 kg (na 2 wózkach)': 'Max. 60 kg (met twee rollers)',
    'Obciążenie do 100 kg (na 2 wózkach)': 'Max. 100 kg (met twee rollers)',
    'Obciążenie do 120 kg (na 2 wózkach)': 'Max. 120 kg (met twee rollers)',
    'Obciążenie do 25 kg': 'Max. 25 kg',
    'Obciążenie do 40 kg': 'Max. 40 kg',
    'Z przezroczystego tworzywa': 'Gemaakt van transparant plastic',
    'Do prowadnicy mocowanej do sufitu': 'Voor aan het plafond gemonteerde rail',
    'Łączy dwie prowadnice mocowane na ścianie': 'Verbindt twee aan de muur gemonteerde rails',
    'Umożliwia montaż prowadnicy na ścianie': 'Om de rail aan de muur te bevestigen',
    'Umożliwia montaż maskownicy drewnianej': 'Voor het monteren van een houten paneel',


}